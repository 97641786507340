import {
    Box,
    Divider,
    Panel,
    PanelContent,
    PanelHeader,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';
import { BankruptcyDetails, BankruptcySection } from '../../../../models';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import YesNoField from '../../../../components/yes-no-field';

/**
 * Component for the Bankruptcy section of the loan program page
 */
const Bankruptcy = ({ loanProgram, isEditing, onEdit }: BankruptcyProps) => {
    /**
     * Helper function to call when any data in the Bankruptcy section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<BankruptcySection>) =>
        onEdit({
            bankruptcy: {
                ...loanProgram.bankruptcy,
                ...data,
            },
        });

    /**
     * Helper function to call when any data in the chapter 7/11 bankruptcy
     * subsection is edited
     *
     * @param bankruptcyDetails any bankruptcy details that are edited
     */
    const onEditChapter7And11 = (
        bankruptcyDetails: Partial<BankruptcyDetails>
    ) =>
        onEditSection({
            chapter7And11: {
                ...loanProgram.bankruptcy.chapter7And11,
                ...bankruptcyDetails,
            },
        });

    /**
     * Helper function to call when any data in the chapter 12/13 bankruptcy
     * subsection is edited
     *
     * @param bankruptcyDetails any bankruptcy details that are edited
     */
    const onEditChapter12And13 = (
        bankruptcyDetails: Partial<BankruptcyDetails>
    ) =>
        onEditSection({
            chapter12And13: {
                ...loanProgram.bankruptcy.chapter12And13,
                ...bankruptcyDetails,
            },
        });

    /**
     * Helper function to call when any data in the chapter 128 bankruptcy
     * subsection is edited
     *
     * @param bankruptcyDetails any bankruptcy details that are edited
     */
    const onEditChapter128 = (bankruptcyDetails: Partial<BankruptcyDetails>) =>
        onEditSection({
            chapter128: {
                ...loanProgram.bankruptcy.chapter128,
                ...bankruptcyDetails,
            },
        });

    // Helper array to make mapping easier for fields
    const bankruptcySubsectionItems: BankruptcySubsectionItem[] = [
        {
            id: '7-11',
            title: 'Chapter 7/11 Bankruptcy',
            data: loanProgram.bankruptcy.chapter7And11,
            onEdit: onEditChapter7And11,
        },
        {
            id: '12-13',
            title: 'Chapter 12/13 Bankruptcy',
            data: loanProgram.bankruptcy.chapter12And13,
            onEdit: onEditChapter12And13,
        },
        {
            id: '128',
            title: 'Chapter 128 Bankruptcy',
            data: loanProgram.bankruptcy.chapter128,
            onEdit: onEditChapter128,
        },
    ];

    return (
        <>
            <Typography variant="h3" gutterBottom>
                Bankruptcy Types
            </Typography>

            <Box marginBottom={2}>
                {bankruptcySubsectionItems.map(
                    ({ id, title, data, onEdit }) => (
                        <Box key={id} marginRight={-2}>
                            <Panel elevation={0} enableCollapse>
                                <PanelHeader
                                    title={title}
                                    titleComponent="h4"
                                />

                                <PanelContent>
                                    <FieldData label="Bankruptcy Status">
                                        {!isEditing ? (
                                            data?.bankruptcyStatus
                                        ) : (
                                            <TextField
                                                fullWidth
                                                placeholder="Bankruptcy Status"
                                                id={`chapter-${id}-bankruptcy-status`}
                                                multiline
                                                minRows={multilineFieldHeight}
                                                value={
                                                    data?.bankruptcyStatus ?? ''
                                                }
                                                onChange={(event) =>
                                                    onEdit({
                                                        bankruptcyStatus:
                                                            event.target
                                                                .value ||
                                                            undefined,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Are loans within this program type dischargeable?">
                                        {!isEditing ? (
                                            data?.areLoansDischargeable
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-are-loans-dischargeable`}
                                                aria-label="Are loans within this program type dischargeable?"
                                                value={
                                                    data?.areLoansDischargeable
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        areLoansDischargeable:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Is suppression applied to borrowers?">
                                        {!isEditing ? (
                                            data?.isSuppressionAppliedToBorrowers
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-apply-suppression-to-borrowers`}
                                                aria-label="Is suppression applied to borrowers?"
                                                value={
                                                    data?.isSuppressionAppliedToBorrowers
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isSuppressionAppliedToBorrowers:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Is suppression applied to co-makers/co-signers?">
                                        {!isEditing ? (
                                            data?.isSuppressionAppliedToComakersOrCosigners
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-apply-suppression-to-comakers-cosigners`}
                                                aria-label="Is suppression applied to co-makers/co-signers?"
                                                value={
                                                    data?.isSuppressionAppliedToComakersOrCosigners
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isSuppressionAppliedToComakersOrCosigners:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Is credit suppression applied to borrowers?">
                                        {!isEditing ? (
                                            data?.isCreditSuppressionAppliedToBorrowers
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-apply-credit-suppression-to-borrowers`}
                                                aria-label="Is credit suppression applied to borrowers?"
                                                value={
                                                    data?.isCreditSuppressionAppliedToBorrowers
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isCreditSuppressionAppliedToBorrowers:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Is credit suppression applied to co-makers/co-signers?">
                                        {!isEditing ? (
                                            data?.isCreditSuppressionAppliedToComakersOrCosigners
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-apply-credit-suppression-to-comakers-cosigners`}
                                                aria-label="Is credit suppression applied to co-makers/co-signers?"
                                                value={
                                                    data?.isCreditSuppressionAppliedToComakersOrCosigners
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isCreditSuppressionAppliedToComakersOrCosigners:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Payments Required During Bankruptcy">
                                        {!isEditing ? (
                                            data?.arePaymentsRequiredDuringBankruptcy
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-payments-required-during-bankruptcy`}
                                                aria-label="Payments Required During Bankruptcy"
                                                value={
                                                    data?.arePaymentsRequiredDuringBankruptcy
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        arePaymentsRequiredDuringBankruptcy:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Cancel Auto Debit Once a Borrower Enters Bankruptcy">
                                        {!isEditing ? (
                                            data?.isAutoDebitCanceledDuringBankruptcy
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-cancel-auto-debit-during-bankruptcy`}
                                                aria-label="Cancel Auto Debit Once a Borrower Enters Bankruptcy"
                                                value={
                                                    data?.isAutoDebitCanceledDuringBankruptcy
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isAutoDebitCanceledDuringBankruptcy:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Can the Borrower Re-Enroll in Auto Debit During Bankruptcy?">
                                        {!isEditing ? (
                                            data?.canReenrollInAutoDebitDuringBankruptcy
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-can-reenroll-in-auto-debit-during-bankruptcy`}
                                                aria-label="Can the Borrower Re-Enroll in Auto Debit During Bankruptcy?"
                                                value={
                                                    data?.canReenrollInAutoDebitDuringBankruptcy
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        canReenrollInAutoDebitDuringBankruptcy:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Is the User in Default During Bankruptcy?">
                                        {!isEditing ? (
                                            data?.isInDefaultDuringBankruptcy
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-in-default-during-bankruptcy`}
                                                aria-label="Is the User in Default During Bankruptcy?"
                                                value={
                                                    data?.isInDefaultDuringBankruptcy
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        isInDefaultDuringBankruptcy:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Will Outstanding Interest Cap At the End of the Bankruptcy Period?">
                                        {!isEditing ? (
                                            data?.willOutstandingInterestCapAtEndOfBankruptcy
                                        ) : (
                                            <YesNoField
                                                id={`chapter-${id}-cap-interest`}
                                                aria-label="Will Outstanding Interest Cap At the End of the Bankruptcy Period?"
                                                value={
                                                    data?.willOutstandingInterestCapAtEndOfBankruptcy
                                                }
                                                onChange={(value) =>
                                                    onEdit({
                                                        willOutstandingInterestCapAtEndOfBankruptcy:
                                                            value,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Additional Details">
                                        {!isEditing ? (
                                            data?.details
                                        ) : (
                                            <TextField
                                                fullWidth
                                                placeholder="Additional Details"
                                                id={`chapter-${id}-additional-details`}
                                                multiline
                                                minRows={multilineFieldHeight}
                                                value={data?.details ?? ''}
                                                onChange={(event) =>
                                                    onEdit({
                                                        details:
                                                            event.target
                                                                .value ||
                                                            undefined,
                                                    })
                                                }
                                            />
                                        )}
                                    </FieldData>
                                </PanelContent>
                            </Panel>

                            <Divider />
                        </Box>
                    )
                )}
            </Box>

            <Typography variant="h3">Additional Details</Typography>
            <FieldData label="Additional Bankruptcy Details">
                {isEditing ? (
                    <TextField
                        value={loanProgram.bankruptcy.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Additional Bankruptcy Details"
                        id="additional-bankruptcy-details"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                ) : (
                    loanProgram.bankruptcy.details
                )}
            </FieldData>
        </>
    );
};

/**
 * Props for the Bankruptcy component
 */
type BankruptcyProps = Required<LoanProgramDataProps>;

/**
 * The data for a subection in the Bankruptcy section
 */
type BankruptcySubsectionItem = {
    /** The unique ID for the section */
    id: string;

    /** The name of the section */
    title: string;

    /** The bankruptcy details for the section */
    data?: BankruptcyDetails;

    /** The function to call when bankruptcy details in the section are edited */
    onEdit: (bankruptcyDetails: Partial<BankruptcyDetails>) => void;
};

export default Bankruptcy;
