import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
} from '@nelnet/unifi-components-react';
import { CancelRefundCashNoncashLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the cash-refund cash/ non-cash matrix table    
 */
const CancelRefundCashNonCashTable = ({
    lenders,
}: CancelRefundCashNoncashTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Lender Name
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>Loan Type</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Cancel-Refund
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Cash/Non-Cash
            </TableCell>
        </TableRow>
    );
    
    // Define a component for rendering the table rows 
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanType) => (
                    <TableRow key={`${lender.lenderId}-${loanType.loanType}`}>
                        <MatrixTableCell>{lender.lenderName}</MatrixTableCell>
                        <MatrixTableCell>{loanType.loanType}</MatrixTableCell>
                        <MatrixTableCell>
                            {loanType.cancelRefund}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanType.cashNoncash}
                        </MatrixTableCell>
                    </TableRow>
                ));
            })}
        </>
    );
    
    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for CancelRefundCashNoncashTable component */
type CancelRefundCashNoncashTableProps = {
    /** An array of CancelRefundCashNoncashLender, for displaying on the frontend */
    lenders: CancelRefundCashNoncashLender[];
};

export default CancelRefundCashNonCashTable;
