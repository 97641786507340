/**
 * Compares two strings alphabetically
 *
 * @param a the first string
 * @param b the second string
 * @returns a number indicating how the two strings should be ordered
 */
export const compareAlphabetically = (a: string, b: string) =>
    a.toLowerCase().localeCompare(b.toLowerCase());

/**
 * Checks whether an HTTP status code is related to the user's token
 * being invalid (401 unauthorized or 403 forbidden)
 *
 * @param httpStatusCode the HTTP status code returned by the request
 * @returns whether the HTTP status code is related to the token being
 *          invalid
 */
export const isInvalidTokenStatusCode = (httpStatusCode: number) =>
    httpStatusCode === 401 || httpStatusCode === 403;

/**
 * Formats a boolean as text
 *
 * @param data a boolean
 * @returns the boolean formatted as text
 */
export const formatBooleanAsText = (data: boolean) => (data ? 'Yes' : 'No');
