import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import FieldData from '../../../../components/field-data';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import YesNoField from '../../../../components/yes-no-field';
import { AutoDebitSection } from '../../../../models';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Auto Debit section of the loan program page
 */
const AutoDebit = ({ loanProgram, isEditing, onEdit }: AutoDebitProps) => {
    /**
     * Helper function to call when any data in the Auto Debit section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<AutoDebitSection>) =>
        onEdit({
            autoDebit: {
                ...loanProgram.autoDebit,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Auto Debit Offered During Originations?">
                {!isEditing ? (
                    loanProgram.autoDebit.offersAutoDebitDuringOriginations
                ) : (
                    <YesNoField
                        id="auto-debit-offered-during-originations"
                        aria-label="Auto Debit Offered During Originations"
                        value={
                            loanProgram.autoDebit
                                .offersAutoDebitDuringOriginations
                        }
                        onChange={(value) =>
                            onEditSection({
                                offersAutoDebitDuringOriginations: value,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Requirements</Typography>
            <FieldData label="Auto Debit Requirements">
                {!isEditing ? (
                    loanProgram.autoDebit.requirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Auto Debit Requirements"
                        id="auto-debit-requirements"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.autoDebit.requirements ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                requirements: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Changes</Typography>
            <FieldData label="Auto Debit Changes">
                {!isEditing ? (
                    loanProgram.autoDebit.changes
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Auto Debit Changes"
                        id="auto-debit-changes"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.autoDebit.changes ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                changes: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Cancellations</Typography>
            <FieldData label="Auto Debit Cancellations">
                {!isEditing ? (
                    loanProgram.autoDebit.cancellations
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Auto Debit Cancellations"
                        id="auto-debit-cancellations"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.autoDebit.cancellations ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                cancellations: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Auto Debit Incentive</Typography>
            <FieldData label="Auto Debit Incentive">
                {loanProgram.autoDebit.incentiveAmount === 0
                    ? 'No'
                    : loanProgram.autoDebit.incentiveAmount}
            </FieldData>
            <FieldData label="Additional Auto Debit Incentive Details">
                {!isEditing ? (
                    loanProgram.autoDebit.incentiveDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Auto Debit Incentive Details"
                        id="additional-auto-debit-incentive-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.autoDebit.incentiveDetails ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                incentiveDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default AutoDebit;

/** Props for the AutoDebit component */
type AutoDebitProps = Required<LoanProgramDataProps>;
