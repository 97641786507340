import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { IdTheftSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the ID Theft section of the loan program page
 */
const IdTheft = ({ loanProgram, isEditing, onEdit }: IdTheftProps) => {
    /**
     * Helper function to call when any data in the ID Theft section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<IdTheftSection>) =>
        onEdit({
            idTheft: {
                ...loanProgram.idTheft,
                ...data,
            },
        });

    return (
        <FieldData label="ID Theft Details">
            {!isEditing ? (
                loanProgram.idTheft.details
            ) : (
                <TextField
                    fullWidth
                    placeholder="ID Theft Details"
                    id="ID-theft-details"
                    multiline
                    minRows={multilineFieldHeight}
                    value={loanProgram.idTheft.details ?? ''}
                    onChange={(event) =>
                        onEditSection({
                            details: event.target.value || undefined,
                        })
                    }
                />
            )}
        </FieldData>
    );
};

export default IdTheft;

/** Props for the IdTheft component */
type IdTheftProps = Required<LoanProgramDataProps>;
