import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    useTheme,
} from '@nelnet/unifi-components-react';
import { BorrowerIncentiveLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the borrower incentive matrix table    
 */
const BorrowerIncentiveTable = ({ lenders }: BorrowerIncentiveTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);
    
    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Incentive Type (AD, OTP, Good Grades, etc.)
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Percentage or Amount Reduced
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                How to Qualify
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                How to be Disqualified
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Special Comments
            </TableCell>
        </TableRow>
    );
    
    // Define a component for rendering the table rows 
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram, index) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}-${loanProgram.loanProgramId}-${index}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.incentiveType}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.percentAmtReduced}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.howToQualify}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.howToDisqualify}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.specialComments}
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );
    
    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for BorrowerIncentiveTable component */
type BorrowerIncentiveTableProps = {
    /** An array of BorrowerIncentiveLender, for displaying on the frontend */
    lenders: BorrowerIncentiveLender[];
};

export default BorrowerIncentiveTable;
