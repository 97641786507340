import { Skeleton } from '@mui/material';
import {
    Box,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from '@nelnet/unifi-components-react';
import { ForbearanceApplications } from '../../models';

/**
 * This component handles rendering the forbearance note table
 */
const ForbearanceApplicationDisplay = ({
    applicationData,
    loadingApp,
    appError,
}: ForbearanceApplicationDisplayProps) => {
    // Render the matrix on the frontend
    return (
        <>
            {appError ? (
                <Typography textAlign="center">
                    There was an error getting data for this matrix
                </Typography>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {loadingApp ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Skeleton
                                    variant="rounded"
                                    width="100%"
                                    height="37.5vh"
                                />
                            </Box>
                        ) : (
                            <Paper
                                padding={1}
                                sx={{ width: '100%', height: '100%' }}
                            >
                                <List
                                    subheader={
                                        <Typography
                                            variant="h3"
                                            component="h2"
                                            padding={2}
                                        >
                                            Forbearance Applications on G Drive
                                        </Typography>
                                    }
                                >
                                    {applicationData &&
                                    applicationData.gDriveApplications &&
                                    applicationData.gDriveApplications.length >
                                        0
                                        ? applicationData.gDriveApplications.map(
                                              (app) => (
                                                  <ListItem key={app}>
                                                      {app}
                                                  </ListItem>
                                              )
                                          )
                                        : 'N/A'}
                                </List>
                            </Paper>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {loadingApp ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Skeleton
                                    variant="rounded"
                                    width="100%"
                                    height="37.5vh"
                                />
                            </Box>
                        ) : (
                            <Paper
                                padding={1}
                                sx={{ width: '100%', height: '100%' }}
                            >
                                <List
                                    subheader={
                                        <Typography
                                            variant="h3"
                                            component="h2"
                                            padding={2}
                                        >
                                            Forbearance Applications ordered on
                                            STAR
                                        </Typography>
                                    }
                                >
                                    {applicationData &&
                                    applicationData.starApplications &&
                                    applicationData.starApplications.length > 0
                                        ? applicationData.starApplications.map(
                                              (app) => (
                                                  <ListItem key={app}>
                                                      {app}
                                                  </ListItem>
                                              )
                                          )
                                        : 'N/A'}
                                </List>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

/** Props for ForbearanceApplicationDisplay component */
type ForbearanceApplicationDisplayProps = {
    /** An array of ForbearanceApplications, for displaying on the frontend */
    applicationData?: ForbearanceApplications;

    /** A boolean to check if the loading data is done or not */
    loadingApp: boolean;

    /** A boolean to check if there's an error occuring while retrieving the data */
    appError: boolean;
};

export default ForbearanceApplicationDisplay;
