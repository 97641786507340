import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    useTheme,
} from '@nelnet/unifi-components-react';
import { DeathLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the lender contacts table    
 */
const BorrowerOnlyLoanTable = ({ lenders }: BorrowerOnlyLoanTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);
    
    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Can We Accept Accurint Screenshot?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Can We Accept an Obit?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Can We Accept a Copy of the Death Certificates?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Send Death Cert Request Letter if Required
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Full Collection & Credit Reporting Suppression Applied to
                Borrower?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Process Admin Forbearance?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Submit AUD?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Change Status on STAR?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Claim File or Write Off Balance?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Submit Relationship Change (Datafix) Request?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Return Payments Received After Borrower Death?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Send Death Forgiveness Letter?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Forgiveness Letter
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Other Information
            </TableCell>
        </TableRow>
    );
    
    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}-${loanProgram.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.canAcceptAccurintScreenShot
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.borrowerOnlyLoan?.canAcceptObit}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.canAcceptDeathCert
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.sendDeathCertReqLetter
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.fullCollectionAndCreditSuppAppliedBorrower
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.processAdminForbearance
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.borrowerOnlyLoan?.submitAUD}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.changeStatusOnSTAR
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.claimFileWriteOffBalance
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.submitRelationChangeReq
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.returnPaymentsReceivedAfterDeathBorrower
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.sendDeathForgiveLetter
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.borrowerOnlyLoan
                                        ?.forgivenessLetter
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.borrowerOnlyLoan?.otherInfo}
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );
    
    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for BorrowerOnlyLoanTable component */
type BorrowerOnlyLoanTableProps = {
    /** An array of DeathLender, for displaying on the frontend */
    lenders: DeathLender[];
};

export default BorrowerOnlyLoanTable;
