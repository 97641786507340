import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { DelinquencySection } from '../../../../models';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Delinquency section of the loan program display
 */
const Delinquency = ({ loanProgram, isEditing, onEdit }: DelinquencyProps) => {
    /**
     * Helper function to call when any data in the Bankruptcy section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<DelinquencySection>) =>
        onEdit({
            delinquency: {
                ...loanProgram.delinquency,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Default</Typography>
            <FieldData label="Days past due when the borrower is in default">
                {loanProgram.delinquency.daysPastDueWhenBorrowerInDefault}
            </FieldData>

            <Typography variant="h3">Phone Calls</Typography>
            {loanProgram.delinquency.phoneCallsSettings &&
            loanProgram.delinquency.phoneCallsSettings.length > 0 ? (
                <Table size="small">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                },
                            }}
                        >
                            <TableCell>Call Start</TableCell>
                            <TableCell>Call End</TableCell>
                            <TableCell>Attempts</TableCell>
                            <TableCell>Contacts</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanProgram.delinquency.phoneCallsSettings.map(
                            (setting) => (
                                <TableRow
                                    key={`${setting.daysLateStart} ${setting.daysLateEnd}`}
                                >
                                    <TableCell>
                                        {setting.daysLateStart ??
                                            'No start provided'}
                                    </TableCell>
                                    <TableCell>
                                        {setting.daysLateEnd ??
                                            'No end provided'}
                                    </TableCell>
                                    <TableCell>
                                        {setting.attempts ??
                                            'No information provided'}
                                    </TableCell>
                                    <TableCell>
                                        {setting.contacts ??
                                            'No information provided'}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            ) : (
                'No information provided'
            )}

            <FieldData label="Additional Deliquency Phone Call Notes">
                {!isEditing ? (
                    loanProgram.delinquency.additionalDelinquencyPhoneCallNotes
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Deliquency Phone Call Notes"
                        id="additional-deliquency-phone-call-notes"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.delinquency
                                .additionalDelinquencyPhoneCallNotes ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDelinquencyPhoneCallNotes:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Emails</Typography>
            <FieldData label="Additional Deliquency - Emails">
                {!isEditing ? (
                    loanProgram.delinquency.additionalDelinquencyEmailNotes
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Deliquency - Emails"
                        id="additional-deliquency-emails"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.delinquency
                                .additionalDelinquencyEmailNotes ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDelinquencyEmailNotes:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Text Messages</Typography>
            <FieldData label="Additional Deliquency - Text">
                {!isEditing ? (
                    loanProgram.delinquency.additionalDelinquencyTextNotes
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Deliquency - Text"
                        id="additional-deliquency-text"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.delinquency
                                .additionalDelinquencyTextNotes ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDelinquencyTextNotes:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Letters</Typography>
            <FieldData label="Number of Days After Which Delinquency Letters are Sent When Borrower is Past Due">
                {loanProgram.delinquency.letters?.daysLate?.join(', ')}
            </FieldData>

            <Typography variant="h3">Additional Delinquency Details</Typography>
            <FieldData label="Additional Deliquency Details">
                {!isEditing ? (
                    loanProgram.delinquency.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Deliquency Details"
                        id="additional-deliquency-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.delinquency.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Delinquency;

/** Props for the Delinquency component */
type DelinquencyProps = Required<LoanProgramDataProps>;
