import {
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@nelnet/unifi-components-react';

/**
 * Component showing an input field with yes and no options
 */
const YesNoField = ({
    value,
    onChange,
    'aria-label': ariaLabel,
    id,
}: YesNoFieldProps) => {
    const onChangeFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Get the user's selected value
        const valueAsString = event.target.value;

        // Convert to a boolean
        const valueAsBoolean =
            valueAsString === 'true'
                ? true
                : valueAsString === 'false'
                ? false
                : undefined;

        // Call the provided onChange function
        onChange(valueAsBoolean);
    };

    return (
        <RadioGroup
            id={id}
            aria-label={ariaLabel}
            row
            value={value === undefined ? '' : String(value)}
            onChange={onChangeFieldValue}
            sx={{ '& > label > *': { fontSize: '1em' } }}
        >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
    );
};

/**
 * Props for the YesNoField component
 */
type YesNoFieldProps = {
    /** The value of the component */
    value?: boolean;

    /** The function to call when the component's value changes */
    onChange: (value?: boolean) => void;

    /** The unique ID of the field */
    id: string;

    /** The label for the field */
    'aria-label': string;
};

export default YesNoField;
