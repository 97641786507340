import { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Typography,
} from '@nelnet/unifi-components-react';
import { Box, Skeleton } from '@mui/material';
import { getAPIBaseURL } from '../../env';
import axios from 'axios';
import { useAuthContext } from '../../contexts/auth-context';
import { isInvalidTokenStatusCode } from '../../utils';
import BorrowerOnlyLoanTable from './borrower-only-loan-table';
import BorrowerComakerLoanTable from './borrower-comaker-loan-table';
import ComakerDeceasedTable from './comaker-deceased-table';
import LenderContactsTable from './lender-contacts-table';
import { DeathLender } from '../../models';

/**
 * The index page for death matrix where it handles getting the data from the endpoint to display for the user
 */
const DeathMatrixPage = () => {
    // Retrieve the base URL
    const baseUrl = getAPIBaseURL();

    // Retrieve the authentication token from the auth-context
    const { token, unauthenticate } = useAuthContext();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const [lenders, setLenders] = useState<DeathLender[]>();

    const [selectedTab, setSelectedTab] = useState(0);

    // Hook to fetch the matrix data from the server when the component mounts or the token changes
    useEffect(() => {
        setLoading(true);
        axios
            .get<DeathLender[]>(`${baseUrl}matrices/death`, {
                headers: {
                    authorization: `bearer ${token}`,
                },
            })
            .then((response) => {
                // If the data is retrieved, set the response data for the matrix data, and set the error and loading state to false
                setLenders(response.data);
                setError(false);
                setLoading(false);
            })
            .catch((error) => {
                // If there is an error, check if it's an invalid token, and unauthenticate it
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    // Otherwise, set the error to true and loading state to false
                    setError(true);
                    setLoading(false);
                }
            });
    }, []);

    return (
        <>
            <Typography mb={4} variant="h1" textAlign="center">
                Death
            </Typography>
            <ButtonGroup size="small" sx={{ mb: 3 }}>
                <Button
                    active={selectedTab === 0}
                    onClick={() => setSelectedTab(0)}
                >
                    Borrower Only Loan
                </Button>
                <Button
                    active={selectedTab === 1}
                    onClick={() => setSelectedTab(1)}
                >
                    Borrower Comaker Loan
                </Button>
                <Button
                    active={selectedTab === 2}
                    onClick={() => setSelectedTab(2)}
                >
                    Comaker Deceased
                </Button>
                <Button
                    active={selectedTab === 3}
                    onClick={() => setSelectedTab(3)}
                >
                    Lender Contacts
                </Button>
            </ButtonGroup>
            {error ? (
                <Typography textAlign="center">
                    There was an error getting data for this matrix
                </Typography>
            ) : loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rounded" width="100%" height="37.5vh" />
                </Box>
            ) : lenders && lenders.length > 0 ? (
                <>
                    {selectedTab === 0 ? (
                        <BorrowerOnlyLoanTable lenders={lenders} />
                    ) : selectedTab === 1 ? (
                        <BorrowerComakerLoanTable lenders={lenders} />
                    ) : selectedTab === 2 ? (
                        <ComakerDeceasedTable lenders={lenders} />
                    ) : selectedTab === 3 ? (
                        <LenderContactsTable lenders={lenders} />
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <Typography textAlign="center">
                    No data is available for this matrix
                </Typography>
            )}
        </>
    );
};

export default DeathMatrixPage;
