import { Login, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Container, TextField } from '@mui/material';
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Paper,
    Typography,
    useTheme,
} from '@nelnet/unifi-components-react';
import axios from 'axios';
import React, { useState } from 'react';
import Alert from '../../components/alert';
import { useAuthContext } from '../../contexts/auth-context';
import { getAPIBaseURL, getAppClientId, getUserPoolId } from '../../env';
import { ReactComponent as Logo } from '../../images/nelnet-logo-white.svg';
import { AuthToken } from '../../models';

/**
 * Component for the Login page
 */
const LoginPage = () => {
    // States for user inputs
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // States for the authentication request to the server
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { authenticate } = useAuthContext();

    const theme = useTheme();
    const logoHeight = theme.spacing(8);

    /**
     * Logs the user into the application
     *
     * @param event the user's submission of the login form
     */
    const onLogIn = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        // Send a request to the server to authenticate the user
        const baseURL = getAPIBaseURL();
        const appClientId = getAppClientId();
        const userPoolId = getUserPoolId();
        const base64 = btoa(`${username}:${password}`);
        await axios
            .get<AuthToken>(`${baseURL}authenticate`, {
                headers: {
                    Authorization: `Basic ${base64}`,
                    appClientId,
                    userPoolId,
                },
            })
            .then((response) => {
                // Authentication was successful
                const { accessToken, expiresIn } = response.data.data;
                setError(false);
                authenticate(accessToken, expiresIn * 1000);
            })
            .catch(() => {
                // Authentication failed due to an error
                setError(true);
                setLoading(false);
            });
    };

    return (
        <Box bgcolor="secondary.dark" minHeight="100vh" width="100%">
            <Container
                maxWidth="sm"
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >
                <Box height={logoHeight} width="100%">
                    <Logo />
                </Box>

                <Paper padding={3} sx={{ width: '100%' }}>
                    <form onSubmit={onLogIn}>
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Typography
                                variant="h1"
                                gutterBottom={Boolean(error)}
                            >
                                Clarity
                            </Typography>

                            {error && (
                                <Alert
                                    text="The username or password is incorrect"
                                    color="error"
                                />
                            )}

                            <TextField
                                required
                                label="Username"
                                value={username}
                                onChange={(event) =>
                                    setUsername(event.target.value)
                                }
                                fullWidth
                                sx={{ marginTop: 2 }}
                            />
                            <TextField
                                required
                                label="Password"
                                value={password}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                                fullWidth
                                sx={{ marginTop: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword
                                                        ? 'Hide Password'
                                                        : 'Show Password'
                                                }
                                                onClick={() =>
                                                    setShowPassword(
                                                        (value) => !value
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                startIcon={
                                    loading ? (
                                        <CircularProgress
                                            size="1em"
                                            color="inherit"
                                        />
                                    ) : (
                                        <Login />
                                    )
                                }
                                disabled={loading}
                                sx={{ marginTop: 2 }}
                            >
                                Log In
                            </Button>
                        </Box>
                    </form>
                </Paper>

                <Box height={logoHeight} />
            </Container>
        </Box>
    );
};

export default LoginPage;
