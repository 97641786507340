import { Switch, Route, Redirect } from 'react-router-dom';
import LoanProgramPage from '../views/loan-program-page';
import LenderPage from '../views/lender-page';
import ErrorPage from '../views/error-page';
import LateFeeMatrix from '../views/late-fee-matrix-page';
import DisabilityMatrix from '../views/disability-matrix-page';
import CancelRefundCashNonCash from '../views/cancel-refund-cash-noncash-matrix-page';
import ChargeOffAndClaimFiledMatrix from '../views/charge-off-claim-filed-matrix-page';
import DeathMatrix from '../views/death-matrix-page';
import BorrowerIncentivePage from '../views/borrower-incentive-matrix-page';
import ForbearanceMatrixPage from '../views/forbearance-matrix-page';
import ScraMatrixPage from '../views/scra-matrix-page';
import StatusPage from '../views/status-page';

/**
 * Component that handles rendering the correct page based on the URL
 */
export const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <Redirect to="/lenders" />
            </Route>
            <Route path="/lenders" exact>
                <LenderPage />
            </Route>

            <Route path="/loan-program/:loanProgramId" exact>
                <LoanProgramPage />
            </Route>

            <Route path="/matrix/borrower-incentive" exact>
                <BorrowerIncentivePage />
            </Route>
            <Route path="/matrix/cancel-refund-cash-noncash" exact>
                <CancelRefundCashNonCash />
            </Route>
            <Route path="/matrix/charge-off-claim-filed" exact>
                <ChargeOffAndClaimFiledMatrix />
            </Route>
            <Route path="/matrix/death" exact>
                <DeathMatrix />
            </Route>
            <Route path="/matrix/disability" exact>
                <DisabilityMatrix />
            </Route>
            <Route path="/matrix/forbearance" exact>
                <ForbearanceMatrixPage />
            </Route>
            <Route path="/matrix/late-fee" exact>
                <LateFeeMatrix />
            </Route>
            <Route path="/matrix/scra" exact>
                <ScraMatrixPage />
            </Route>

            <Route path="/status" exact>
                <StatusPage />
            </Route>

            <Route path="*">
                <ErrorPage />
            </Route>
        </Switch>
    );
};

export default Routes;
