import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@nelnet/unifi-components-react';
import { ForbearanceNoteLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import { Skeleton } from '@mui/material';
import { TableContainer } from '@mui/material';
import MarkdownFormatter from '../../components/markdown-formatter';

/**
 * This component handles rendering the forbearance note table
 */
const ForbearanceNoteTemplateDisplay = ({
    lenderNotes,
    loadingNote,
    noteError,
}: ForbearanceNoteTemplateDisplayProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Extended Grace
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Extension
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenderNotes ? (
                lenderNotes.map((lender) =>
                    lender.data.map((data) => (
                        <TableRow key={`${lender.lenderId}`}>
                            <TableCell>{lender.lenderName}</TableCell>
                            <TableCell>{data.description}</TableCell>
                            <TableCell>
                                <Typography fontSize="inherit" paragraph>
                                    {data.extendedGrace?.maxBillingCyclesAllowed
                                        ? `${data.extendedGrace.maxBillingCyclesAllowed} month max`
                                        : 'No max billings cycles were found'}
                                </Typography>
                                <Typography fontSize="inherit" paragraph>
                                    {data.extendedGrace
                                        ?.maxBillingCyclesPerInstance
                                        ? `${data.extendedGrace.maxBillingCyclesPerInstance} months at a time`
                                        : 'No max billing cycles per instance were found'}
                                </Typography>
                                <MarkdownFormatter>
                                    {data.extendedGrace?.details ??
                                        'No details were found'}
                                </MarkdownFormatter>
                            </TableCell>
                            <TableCell>
                                <Typography fontSize="inherit" paragraph>
                                    {data.extension?.maxBillingCyclesAllowed
                                        ? `${data.extension.maxBillingCyclesAllowed} month max`
                                        : 'No max billings cycles were found'}
                                </Typography>
                                <Typography fontSize="inherit" paragraph>
                                    {data.extension?.maxBillingCyclesPerInstance
                                        ? `Count towards ${data.extension.maxBillingCyclesPerInstance} month max allowed`
                                        : 'No max billings cycles were found'}
                                </Typography>
                                <MarkdownFormatter>
                                    {data.extension?.details ??
                                        'No details were found'}
                                </MarkdownFormatter>
                            </TableCell>
                        </TableRow>
                    ))
                )
            ) : (
                <></>
            )}
        </>
    );

    // Render the matrix on the frontend
    return (
        <>
            {noteError ? (
                <Typography textAlign="center">
                    There was an error getting data for this matrix
                </Typography>
            ) : loadingNote ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rounded" width="100%" height="37.5vh" />
                </Box>
            ) : lenderNotes && lenderNotes.length > 0 ? (
                <Paper style={{ width: '100%', overflow: 'auto' }}>
                    <TableContainer sx={{ maxHeight: '80vh' }}>
                        <Table
                            size="medium"
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <TableHeaders />
                            </TableHead>
                            <TableBody>
                                <TableRows />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Typography textAlign="center">
                    No matrix data was found
                </Typography>
            )}
        </>
    );
};

/** Props for ForbearanceNoteTemplateDisplay component */
type ForbearanceNoteTemplateDisplayProps = {
    /** An array of ForbearanceNoteLender, for displaying on the frontend */
    lenderNotes?: ForbearanceNoteLender[];

    /** A boolean to check if the loading data is done or not */
    loadingNote: boolean;

    /** A boolean to check if there's an error occuring while retrieving the data */
    noteError: boolean;
};

export default ForbearanceNoteTemplateDisplay;
