import { useEffect, useState } from 'react';
import LoanProgramData from './components/loan-program-data';
import {
    Grid,
    Typography,
    CircularProgress,
    Snackbar,
    Notification,
} from '@nelnet/unifi-components-react';
import axios from 'axios';
import { getAPIBaseURL } from '../../env';
import { Link, useParams } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useAuthContext } from '../../contexts/auth-context';
import { isInvalidTokenStatusCode } from '../../utils';
import ScrollTopButton from '../../components/scroll-to-the-top';
import ErrorPage from '../error-page';
import { Check, Error, Save } from '@mui/icons-material';
import LoanProgramErrorBoundary from './components/loan-program-error-boundary';
import { Forbearance, LoanProgram, LoanProgramEditBody } from '../../models';
import LoanProgramHeader from './components/loan-program-header';

/**
 * Component for the loan program page, which handles the viewing and editing
 * of a loan program
 */
const LoanProgramPage = () => {
    // Get the loan program ID from the URL
    const { loanProgramId } = useParams<LoanProgramPageUrlParams>();

    // The original, unedited loan program
    const [originalLoanProgram, setOriginalLoanProgram] =
        useState<LoanProgram>();

    // The loan program being edited
    const [loanProgram, setLoanProgram] = useState<LoanProgram>();

    // Error states for server requests to get or save the loan program
    const [errorGettingLoanProgram, setErrorGettingLoanProgram] =
        useState(false);
    const [errorSavingLoanProgram, setErrorSavingLoanProgram] = useState(false);

    // State for whether the user is currently editing the page
    const [isEditing, setIsEditing] = useState(false);

    // State for whether the application is currently saving changes to the loan program
    const [isSaving, setIsSaving] = useState(false);

    // State for whether the snackbar confirming the user's changes is open
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);

    const { token, unauthenticate } = useAuthContext();
    const baseUrl = getAPIBaseURL();

    // On page load, fetch the loan program based on the ID in the URL
    useEffect(() => {
        axios
            .get<LoanProgram>(`${baseUrl}loanprograms/${loanProgramId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setLoanProgram({ ...response.data });
                setOriginalLoanProgram({ ...response.data });
                setErrorGettingLoanProgram(false);
            })
            .catch((error) => {
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    setErrorGettingLoanProgram(true);
                }
            });
    }, []);

    /**
     * Handles edits to the loan program data by updating the loan program object
     *
     * @param newLoanProgramData new data to add to the loan program
     */
    const onEdit = (newLoanProgramData: Partial<LoanProgram>) => {
        setLoanProgram(
            (loanProgram) =>
                loanProgram && { ...loanProgram, ...newLoanProgramData }
        );
    };

    /**
     * Saves the user's changes to a loan program to the server
     *
     * @param event the user's submission of the loan program editing form
     */
    const onSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (loanProgram) {
            setIsSaving(true);

            axios
                .put<LoanProgramEditBody>(
                    `${baseUrl}loanprograms/${loanProgramId}`,
                    loanProgram,
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then((response) => {
                    try {
                        const { data: updatedEditableLoanProgram } = response;

                        // Combine updated editable fields with uneditable fields to be displayed
                        // on the page after saving
                        const updatedLoanProgram: LoanProgram = {
                            ...loanProgram,
                            ...updatedEditableLoanProgram,
                            feesInformation: {
                                lateFee: {
                                    ...loanProgram.feesInformation.lateFee,
                                    ...updatedEditableLoanProgram
                                        .feesInformation.lateFee,
                                },
                                nsfFee: {
                                    ...loanProgram.feesInformation.nsfFee,
                                    ...updatedEditableLoanProgram
                                        .feesInformation.nsfFee,
                                },
                            },
                            loanModifications: {
                                ...loanProgram.loanModifications,
                                ...updatedEditableLoanProgram.loanModifications,
                            },
                            loanPeriod: {
                                ...loanProgram.loanPeriod,
                                ...updatedEditableLoanProgram.loanPeriod,
                                forbearances:
                                    loanProgram.loanPeriod.forbearances.map(
                                        (forbearance) => {
                                            const updatedClarityForbearance =
                                                updatedEditableLoanProgram.loanPeriod.forbearances?.find(
                                                    (clarityForb) =>
                                                        clarityForb.forbearanceSettingId ===
                                                        forbearance.forbearanceSettingId
                                                );

                                            const combinedForbearance: Forbearance =
                                                {
                                                    ...forbearance,
                                                    ...updatedClarityForbearance,
                                                };
                                            return combinedForbearance;
                                        }
                                    ),
                            },
                        };
                        setOriginalLoanProgram(updatedLoanProgram);
                        setLoanProgram(updatedLoanProgram);

                        // The loan program has successfully saved
                        setErrorSavingLoanProgram(false);

                        // The user is no longer editing
                        setIsEditing(false);
                    } catch {
                        setErrorSavingLoanProgram(true);
                    }
                })
                .catch(() => {
                    setErrorSavingLoanProgram(true);
                })
                .finally(() => {
                    setIsSaving(false);
                    setSnackbarOpen(true);
                });
        }
    };

    /**
     * Cancels the user's changes to the loan program
     */
    const onCancel = () => {
        if (originalLoanProgram) {
            setLoanProgram({ ...originalLoanProgram });
        }
        setIsEditing(false);
    };

    return (
        <Container>
            {errorGettingLoanProgram ? (
                <ErrorPage />
            ) : (
                <form onSubmit={onSave}>
                    <LoanProgramErrorBoundary loanProgram={loanProgram}>
                        <Grid container justifyContent="space-between">
                            <Grid>
                                <Button
                                    startIcon={<ArrowBackIosNewIcon />}
                                    component={Link}
                                    to="/lenders"
                                    color="grey"
                                    size="small"
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid>
                                {!isEditing ? (
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => setIsEditing(true)}
                                        disableElevation
                                        disabled={!loanProgram}
                                    >
                                        Edit
                                    </Button>
                                ) : (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                disableElevation
                                                type="submit"
                                                disabled={isSaving}
                                                startIcon={
                                                    isSaving ? (
                                                        <CircularProgress
                                                            size="1em"
                                                            color="inherit"
                                                        />
                                                    ) : (
                                                        <Save />
                                                    )
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onClick={onCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <LoanProgramHeader loanProgram={loanProgram} />

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            autoHideDuration={6000}
                            open={isSnackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            ContentProps={{
                                'aria-describedby':
                                    'loan-program-snackbar-title',
                                role: 'alert',
                            }}
                        >
                            <Notification
                                type={
                                    errorSavingLoanProgram ? 'error' : 'success'
                                }
                                icon={
                                    errorSavingLoanProgram ? (
                                        <Error />
                                    ) : (
                                        <Check />
                                    )
                                }
                            >
                                <Typography id="loan-program-snackbar-title">
                                    {errorSavingLoanProgram
                                        ? 'There was an error saving the changes'
                                        : 'Changes have been saved'}
                                </Typography>
                            </Notification>
                        </Snackbar>

                        <LoanProgramData
                            loanProgram={loanProgram}
                            isEditing={isEditing}
                            onEdit={onEdit}
                        />
                    </LoanProgramErrorBoundary>
                </form>
            )}

            <ScrollTopButton />
        </Container>
    );
};
export default LoanProgramPage;

/**
 * URL parameters for the loan program page
 */
type LoanProgramPageUrlParams = {
    /** The ID of the loan program to be displayed on the page */
    loanProgramId: string;
};
