import { LoanProgram } from '../../../models';
import {
    AutoDebit,
    Bankruptcy,
    CoSignerRelease,
    CreditReporting,
    Death,
    Delinquency,
    Disability,
    DueDates,
    Fees,
    IdTheft,
    Incentives,
    InterestRates,
    LenderInformation,
    LoanModifications,
    LoanPeriods,
    Originations,
    Payments,
    Reamortization,
    RepaymentTerms,
    SchoolClosing,
    ServicingLoanProgramInformation,
    Statements,
    TaxReporting,
    ThirdPartyCollector,
} from '../sections';
import { compareAlphabetically } from '../../../utils';

/**
 * Formats the display of a loan program's sections for display on the loan
 * program page
 *
 * @param loanProgram the loan program to be formatted
 * @param isEditing whether the user is editing the loan program
 * @param onEdit function to call whenever the user edits the loan program
 * @returns the display for the loan program's data
 */
export const formatLoanProgramDataDisplay = (
    loanProgram: LoanProgram,
    isEditing: boolean,
    onEdit: (data: Partial<LoanProgram>) => void
) => {
    // Data that should be presented at the top of the display
    const overviewData: LoanProgramSectionDisplay[] = [
        {
            header: 'Lender Information',
            display: <LenderInformation loanProgram={loanProgram} />,
        },
        {
            header: 'Servicing Loan Program Information',
            display: (
                <ServicingLoanProgramInformation
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
    ];

    // Data that should be presented lower in the display
    const additionalData: LoanProgramSectionDisplay[] = [
        {
            header: '1098-E/1099 - Tax Reporting',
            display: (
                <TaxReporting
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Auto Debit',
            display: (
                <AutoDebit
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Bankruptcy',
            display: (
                <Bankruptcy
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },

        {
            header: 'Co-Signer Release',
            display: (
                <CoSignerRelease
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Credit Reporting',
            display: (
                <CreditReporting
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Death',
            display: (
                <Death
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Delinquency',
            display: (
                <Delinquency
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Disability',
            display: (
                <Disability
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Due Dates',
            display: (
                <DueDates
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Fees',
            display: (
                <Fees
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'ID Theft',
            display: (
                <IdTheft
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Incentives',
            display: (
                <Incentives
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Interest Rates',
            display: (
                <InterestRates
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Loan Modifications',
            display: (
                <LoanModifications
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
            subHeaders: getLoanModificationsSubHeaders(loanProgram),
        },
        {
            header: 'Originations',
            display: (
                <Originations
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Loan Periods (Repayment and Payment Relief)',
            display: (
                <LoanPeriods
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
            subHeaders: getLoanPeriodSubHeaders(loanProgram),
        },
        {
            header: 'Payments',
            display: (
                <Payments
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Re-Amortization',
            display: (
                <Reamortization
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Repayment Terms',
            display: (
                <RepaymentTerms
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'School Closing',
            display: (
                <SchoolClosing
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Statements',
            display: (
                <Statements
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
        {
            header: 'Third Party Collector',
            display: (
                <ThirdPartyCollector
                    loanProgram={loanProgram}
                    isEditing={isEditing}
                    onEdit={onEdit}
                />
            ),
        },
    ];

    // Alphabetize the additional data
    additionalData.sort((a, b) => compareAlphabetically(a.header, b.header));

    // Combine all data
    const accordionData: LoanProgramSectionDisplay[] = [
        ...overviewData,
        ...additionalData,
    ];
    return accordionData;
};

/**
 * Extracts the subheaders from the Loan Modifications section of the loan program
 *
 * @param loanProgram the loan program for which to extract subheaders
 * @returns a list of subheaders
 */
const getLoanModificationsSubHeaders = (loanProgram: LoanProgram) => {
    const subHeadersWithUndefinedValues =
        loanProgram.loanModifications.modifications.map(
            (mod) => mod.description
        );
    const subHeaders: string[] = [];
    subHeadersWithUndefinedValues.forEach((subHeader) => {
        if (subHeader) {
            subHeaders.push(subHeader);
        }
    });

    return subHeaders;
};

/**
 * Extracts the subheaders from the Loan Period section of the loan program
 *
 * @param loanProgram the loan program for which to extract subheaders
 * @returns a list of subheaders
 */
const getLoanPeriodSubHeaders = (loanProgram: LoanProgram) => {
    const subHeadersWithUndefinedValues =
        loanProgram.loanPeriod.forbearances.map(
            (forbearance) => forbearance.name
        );
    const subHeaders: string[] = [];
    subHeadersWithUndefinedValues.forEach((subHeader) => {
        if (subHeader) {
            subHeaders.push(subHeader);
        }
    });
    return subHeaders;
};

/**
 * A display of a section of loan program data
 */
export type LoanProgramSectionDisplay = {
    /** The section's header */
    header: string;

    /** Any subheaders within the section */
    subHeaders?: string[];

    /** The display to render for the contents of the section */
    display: React.ReactNode;
};
