import { TableContainer, useTheme } from '@mui/material';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@nelnet/unifi-components-react';
import { tableHeadersSpacing } from '../../global';
import { LateFeeLender } from '../../models';
import MatrixTableCell from '../../components/matrix-table-cell';

/**
 * This component handles rendering the late fee table
 */
const LateFeeTable = ({ lenders }: LateFeeTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );

    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Late Fee Amount
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Late Fee Type
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Grace Days
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Late Fee Waive
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Minimum
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Maximum
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}-${loanProgram.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.feeAmount}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.feeAmountType}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.graceDays}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.waive}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.minimumFee}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.maximumFee}
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );

    // Render the matrix on the frontend
    return (
        <Paper
            sx={{
                width: '100%',
                overflow: 'auto',
            }}
        >
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader>
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

type LateFeeTableProps = {
    lenders: LateFeeLender[];
};

export default LateFeeTable;
