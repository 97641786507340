import {
    Panel,
    PanelHeader,
    PanelContent,
    Paper,
    Divider,
    Box,
    useTheme,
    Button,
    Grid,
} from '@nelnet/unifi-components-react';
import { LoanProgram } from '../../../models';
import { useEffect, useState } from 'react';
import { lighten, Skeleton, Typography } from '@mui/material';
import SearchBar from '../../../components/search-bar';
import {
    LoanProgramSectionDisplay,
    formatLoanProgramDataDisplay,
} from '../utils';

/**
 * Component that displays all sections on the loan program page
 */
const LoanProgramData = ({
    loanProgram,
    isEditing,
    onEdit,
}: LoanProgramDataProps) => {
    const theme = useTheme();

    // Display for the loan program data
    const [loanProgramDataDisplay, setLoanProgramDisplay] =
        useState<LoanProgramSectionDisplay[]>();

    // Text that the user has entered into the search bar
    const [searchInput, setSearchInput] = useState('');

    // Whether the user has toggled the "Expand All" functionality for loan
    // program sections' panels
    const [expandedAll, setExpandedAll] = useState(false);

    // Refresh the display whenever the loan program or search input changes
    // or if the user enters or exits edit mode
    useEffect(() => {
        if (loanProgram) {
            setLoanProgramDisplay(
                formatLoanProgramDataDisplay(
                    loanProgram,
                    isEditing,
                    onEdit
                ).filter(
                    (item) =>
                        (item.subHeaders &&
                            isSearchingForSubHeader(item.subHeaders)) ||
                        isSearchingForHeader(item.header)
                )
            );
        }
    }, [loanProgram, searchInput, isEditing]);

    /**
     * Determines whether the user's search input matches the given header
     *
     * @param loanProgramDataItemHeader the header
     * @returns true if the header matches the user's search input or false otherwise
     */
    const isSearchingForHeader = (loanProgramDataItemHeader: string) => {
        return loanProgramDataItemHeader
            .toLowerCase()
            .includes((searchInput || '').toLowerCase());
    };

    /**
     * Determines whether the user's search input matches the given subheaders
     *
     * @param subHeaders the subheaders
     * @returns true if the subheader matches the user's search input or false otherwise
     */
    const isSearchingForSubHeader = (subHeaders: string[]) => {
        if (searchInput.length === 0) {
            return false;
        } else {
            return subHeaders.some((data) =>
                data.toLowerCase().includes(searchInput.toLowerCase())
            );
        }
    };

    return (
        <>
            <Grid container alignItems="center" sx={{ pb: 3 }} gap={2}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <SearchBar
                        label="Search for sections"
                        disabled={!loanProgram}
                        searchTerm={searchInput}
                        setSearchTerm={setSearchInput}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setExpandedAll((value) => !value)}
                        disabled={!loanProgram}
                    >
                        {expandedAll ? 'Collapse All' : 'Expand All'}
                    </Button>
                </Grid>
            </Grid>

            {loanProgram && loanProgramDataDisplay ? (
                loanProgramDataDisplay.length > 0 ? (
                    <Paper square>
                        {loanProgramDataDisplay.map((item, index) => (
                            <Box key={item.header}>
                                <Panel
                                    elevation={0}
                                    enableCollapse
                                    sx={{ borderRadius: 0 }}
                                    expanded={
                                        (item.subHeaders &&
                                            !isSearchingForHeader(
                                                item.header
                                            )) ||
                                        expandedAll
                                    }
                                >
                                    <PanelHeader
                                        title={item.header}
                                        titleComponent="h2"
                                        style={{
                                            backgroundColor:
                                                theme.palette.secondary.dark,
                                            color: theme.palette.secondary
                                                .contrastText,
                                            borderRadius: 0,
                                        }}
                                    />
                                    <PanelContent>
                                        <Box>{item.display}</Box>
                                    </PanelContent>
                                </Panel>
                                {index < loanProgramDataDisplay.length - 1 && (
                                    <Divider
                                        sx={{
                                            borderColor: lighten(
                                                theme.palette.secondary.dark,
                                                0.4
                                            ),
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                    </Paper>
                ) : (
                    <Typography sx={{ textAlign: 'left', pb: 5 }}>
                        There are no sections that match the search.
                    </Typography>
                )
            ) : (
                <Skeleton width="100%" height="37.5vh" variant="rounded" />
            )}
        </>
    );
};

/**
 * Props for the LoanProgramData component
 */
export type LoanProgramDataProps = {
    /** The loan program to display */
    loanProgram?: LoanProgram;

    /** Whether the user is editing the loan program */
    isEditing: boolean;

    /** The function to call when the user edits the loan program */
    onEdit: (data: Partial<LoanProgram>) => void;
};

export default LoanProgramData;
