/**
 * Object containing the widths of matrix table columns for various
 * data types (booleans/numbers, single-line text fields, and multiline
 * text fields)
 */
export const tableHeadersSpacing = {
    minWidthBooleansOrNumbers: 20,
    minWidthOneLine: 25,
    minWidthMultipleLines: 50,
};

/** The minimum number of lines for a multiline input field */
export const multilineFieldHeight = 3;
