import { TableCell } from '@nelnet/unifi-components-react';
import { ReactNode } from 'react';
import MarkdownFormatter from './markdown-formatter';
import { formatBooleanAsText } from '../utils';

/**
 * Component that displays a table cell of matrix data within a matrix table
 */
const MatrixTableCell = (props: MatrixTableCellProps) => {
    let tableCellContents: ReactNode;
    if (
        props.children === undefined ||
        props.children === null ||
        props.children === '' ||
        (Array.isArray(props.children) && props.children.length === 0)
    ) {
        // If no data was provided, show empty data
        tableCellContents = 'N/A';
    } else if (typeof props.children === 'number') {
        // For numerical data, display the number
        tableCellContents = props.children;
    } else if (typeof props.children === 'string') {
        // For text data, format with Markdown
        tableCellContents = (
            <MarkdownFormatter>{props.children}</MarkdownFormatter>
        );
    } else if (typeof props.children === 'boolean') {
        // For boolean data, display the boolean as text
        tableCellContents = formatBooleanAsText(props.children);
    } else {
        // For all other types of data, display the data as provided
        tableCellContents = props.children;
    }

    return <TableCell>{tableCellContents}</TableCell>;
};
export default MatrixTableCell;

/**
 * Props for the Matrix Table Cell component
 */
type MatrixTableCellProps = {
    /** Contents of the matrix table cell */
    children?: ReactNode;
};
