import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { TaxReportingSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import YesNoField from '../../../../components/yes-no-field';

/**
 * Component for the Tax Reporting section of the loan program page
 */
const TaxReporting = ({
    loanProgram,
    isEditing,
    onEdit,
}: TaxReportingProps) => {
    /**
     * Helper function to call when any data in the Tax Reporting section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<TaxReportingSection>) =>
        onEdit({
            taxReporting: {
                ...loanProgram.taxReporting,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Does Firstmark Services send 1098-Es on behalf of the lender?">
                {!isEditing ? (
                    loanProgram.taxReporting.send1098eOnBehalfOfLender
                ) : (
                    <YesNoField
                        id="send-1098e-on-behalf-of-lender"
                        aria-label="Sends 1098-Es on behalf of the lender"
                        value={
                            loanProgram.taxReporting.send1098eOnBehalfOfLender
                        }
                        onChange={(value) =>
                            onEditSection({
                                send1098eOnBehalfOfLender: value,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Does Firstmark Services send 1099s on behalf of the lender?">
                {!isEditing ? (
                    loanProgram.taxReporting.send1099OnBehalfOfLender
                ) : (
                    <YesNoField
                        id="send-1099-on-behalf-of-lender"
                        aria-label="Sends 1099s on behalf of the lender"
                        value={
                            loanProgram.taxReporting.send1099OnBehalfOfLender
                        }
                        onChange={(value) =>
                            onEditSection({
                                send1099OnBehalfOfLender: value,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Tax ID Number">
                {!isEditing ? (
                    loanProgram.taxReporting.taxIdNumber
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Tax ID Number"
                        id="tax-id-number"
                        value={loanProgram.taxReporting.taxIdNumber ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                taxIdNumber: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Tax Resources Page">
                {!isEditing ? (
                    loanProgram.taxReporting.taxResourcesPageLink
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Tax Resources Page"
                        id="tax-resources-page"
                        value={
                            loanProgram.taxReporting.taxResourcesPageLink ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                taxResourcesPageLink:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default TaxReporting;

/** Props for the TaxReporting component */
type TaxReportingProps = Required<LoanProgramDataProps>;
