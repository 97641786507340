import { Fab } from '@nelnet/unifi-components-react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/**
 * Component that renders a scroll-to-top button.
 * When clicked, the button smoothly scrolls the window back to the top.
 */
export const ScrollTopButton = () => {
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Fab
            sx={{
                position: 'fixed',
                alignContent: 'center',
                margin: 0,
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
            }}
            onClick={handleClick}
            color="primary"
            aria-label="Scroll to top"
        >
            <KeyboardArrowUpIcon />
        </Fab>
    );
};

export default ScrollTopButton;
