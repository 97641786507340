import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { CreditReportingSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Credit Reporting section of the loan program page
 */
const CreditReporting = ({
    loanProgram,
    isEditing,
    onEdit,
}: CreditReportingProps) => {
    /**
     * Helper function to call when any data in the Credit Reporting section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<CreditReportingSection>) =>
        onEdit({
            creditReporting: {
                ...loanProgram.creditReporting,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Reporting</Typography>

            <FieldData label="Deliquency Reporting Begins">
                {!isEditing ? (
                    loanProgram.creditReporting.delinquencyReportingWillBegin
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Deliquency Reporting Begins"
                        id="deliquency-reporting-begins"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.creditReporting
                                .delinquencyReportingWillBegin ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                delinquencyReportingWillBegin:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <FieldData label="Additional credit reporting details">
                {!isEditing ? (
                    loanProgram.creditReporting.additionalDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional credit reporting details"
                        id="additional-credit-reporting-begins"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.creditReporting.additionalDetails ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Retractions</Typography>
            <FieldData label="Additional credit reporting retraction details">
                {!isEditing ? (
                    loanProgram.creditReporting
                        .additionalCreditReportingRetractionDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional credit reporting retraction details"
                        id="additional-credit-reporting-retraction-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.creditReporting
                                .additionalCreditReportingRetractionDetails ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalCreditReportingRetractionDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default CreditReporting;

/** Props for the CreditReporting component */
type CreditReportingProps = Required<LoanProgramDataProps>;
