import { Box, Skeleton } from '@mui/material';
import { LoanProgram } from '../../../models';
import { Typography } from '@nelnet/unifi-components-react';

/**
 * The header (title and subtitle) for the loan program page
 */
const LoanProgramHeader = ({ loanProgram }: LoanProgramHeaderProps) => {
    return (
        <Box marginBottom={3}>
            <Typography
                variant="h1"
                component={loanProgram ? undefined : 'div'}
                textAlign="center"
            >
                {loanProgram ? (
                    loanProgram.description ?? 'Loan Program'
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Skeleton width="30%" />
                    </Box>
                )}
            </Typography>
            <Typography
                variant="subtitle1"
                component={loanProgram ? undefined : 'div'}
                textAlign="center"
            >
                {loanProgram ? (
                    loanProgram.loanProgramNumber ??
                    'No Loan Program Code Available'
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Skeleton width="20%" />
                    </Box>
                )}
            </Typography>
        </Box>
    );
};

export default LoanProgramHeader;

/**
 * Props for the LoanProgramHeader component
 */
type LoanProgramHeaderProps = {
    loanProgram?: LoanProgram;
};
