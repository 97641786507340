import { useEffect, useState } from 'react';
import {
    Typography,
    ButtonGroup,
    Button,
} from '@nelnet/unifi-components-react';
import { Box, Skeleton } from '@mui/material';
import ChargeOffAndClaimFiledTable from './charge-off-claim-filed-table';
import { getAPIBaseURL } from '../../env';
import axios from 'axios';
import { useAuthContext } from '../../contexts/auth-context';
import { isInvalidTokenStatusCode } from '../../utils';
import { ChargeOffClaimFiledLender } from '../../models';
/**
 * The index page for charge off and claim filed where it handles getting the data from the endpoint to display for the user
 */
const ChargeOffAndClaimFiledMatrix = () => {
    // Retrieve the base URL
    const baseUrl = getAPIBaseURL();

    // Retrieve the authentication token from the auth-context
    const [lenders, setLenders] = useState<ChargeOffClaimFiledLender[]>();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const { token, unauthenticate } = useAuthContext();

    const [selectedTab, setSelectedTab] = useState(0);

    // Hook to fetch the matrix data from the server when the component mounts or the token changes
    useEffect(() => {
        setLoading(true);
        axios
            .get<ChargeOffClaimFiledLender[]>(
                `${baseUrl}matrices/charge-off-claim-filed`,
                {
                    headers: {
                        authorization: `bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                // If the data is retrieved, set the response data for the matrix data, and set the error and loading state to false
                setLenders(response.data);
                setError(false);
                setLoading(false);
            })
            .catch((error) => {
                // If there is an error, check if it's an invalid token, and unauthenticate it
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    // Otherwise, set the error to true and loading state to false
                    setError(true);
                    setLoading(false);
                }
            });
    }, []);

    return (
        <>
            <Typography mb={4} variant="h1" textAlign="center">
                Charge Off and Claim Filed
            </Typography>
            <ButtonGroup size="small" sx={{ mb: 3 }}>
                <Button
                    active={selectedTab === 0}
                    onClick={() => setSelectedTab(0)}
                >
                    Charge Off
                </Button>
                <Button
                    active={selectedTab === 1}
                    onClick={() => setSelectedTab(1)}
                >
                    Velocity Charge Off
                </Button>
            </ButtonGroup>
            {error ? (
                <Typography textAlign="center">
                    There was an error getting data for this matrix
                </Typography>
            ) : loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rounded" width="100%" height="37.5vh" />
                </Box>
            ) : lenders && lenders.length > 0 ? (
                <>
                    <ChargeOffAndClaimFiledTable
                        selectedTab={selectedTab}
                        lenders={lenders}
                    />
                </>
            ) : (
                <Typography>No data is available for this matrix</Typography>
            )}
        </>
    );
};

export default ChargeOffAndClaimFiledMatrix;
