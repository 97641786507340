import { createContext, useContext, useEffect, useState } from 'react';

/**
 * The application context for authentication
 */
const AuthContext = createContext<AuthContextValue>({
    authenticate: () => undefined,
    unauthenticate: () => undefined,
});

/**
 * The context provider for authentication
 */
export const AuthContextProvider = (props: Props) => {
    const [token, setToken] = useState(getTokenFromStorage());
    const [tokenExpiration, setTokenExpiration] = useState(
        getTokenExpirationFromStorage()
    );

    /**
     * Authenticates the user
     *
     * @param token the user's authentication token
     */
    const authenticate = (token: string, tokenExpiration: number) => {
        setToken(token);
        setTokenInStorage(token);

        setTokenExpiration(tokenExpiration);
        setTokenExpirationInStorage(tokenExpiration);
    };

    /**
     * Unauthenticates the user
     */
    const unauthenticate = () => {
        setToken(undefined);
        clearTokenFromStorage();

        setTokenExpiration(undefined);
        clearTokenExpirationFromStorage();
    };

    // If the token is expired, sign the user out
    useEffect(() => {
        if (tokenExpiration) {
            const today = new Date().getTime();
            if (today > tokenExpiration) {
                unauthenticate();
            }
        }
    }, [tokenExpiration]);

    return (
        <AuthContext.Provider value={{ token, authenticate, unauthenticate }}>
            {props.children}
        </AuthContext.Provider>
    );
};

/**
 * Properties for the authentication context providers
 */
type Props = {
    children: React.ReactNode;
};

/**
 * The values stored in the authentication context
 */
type AuthContextValue = {
    token?: string;
    authenticate: (token: string, tokenExpiration: number) => void;
    unauthenticate: () => void;
};

/**
 * The local storage field where the user's authentication token is stored
 */
const tokenStorageField = 'token';

/**
 * Stores the user's authentication token
 *
 * @param token the user's authentication token
 */
const setTokenInStorage = (token: string) =>
    localStorage.setItem(tokenStorageField, token);

/**
 * Clears the user's authentication token from storage
 */
const clearTokenFromStorage = () => localStorage.removeItem(tokenStorageField);

/**
 * Gets the user's authentication token from storage
 *
 * @returns the user's authentication token or `undefined` if no token exists
 *          in storage
 */
const getTokenFromStorage = () =>
    localStorage.getItem(tokenStorageField) ?? undefined;

/**
 * The local storage field where the expiration date of the user's authentication
 * token is stored
 */
const tokenExpirationStorageField = 'token-expiration';

/**
 * Stores the expiration date of the user's authentication token
 *
 * @param tokenExpiration the expiration date of the user's authentication token
 */
const setTokenExpirationInStorage = (tokenExpiration: number) =>
    localStorage.setItem(
        tokenExpirationStorageField,
        tokenExpiration.toString()
    );

/**
 * Clears the expiration date of the user's authentication token from storage
 */
const clearTokenExpirationFromStorage = () =>
    localStorage.removeItem(tokenExpirationStorageField);

/**
 * Gets the expiration date of the user's authentication token from storage
 *
 * @returns the expiration date of the user's authentication token or
 *          `undefined` if no expiration date exists in storage
 */
const getTokenExpirationFromStorage = () => {
    const tokenExpirationFromStorage = localStorage.getItem(
        tokenExpirationStorageField
    );
    if (tokenExpirationFromStorage) {
        try {
            const tokenExpiration = parseInt(tokenExpirationFromStorage);
            return tokenExpiration;
        } catch (error) {
            return undefined;
        }
    } else {
        return undefined;
    }
};

/**
 * A React hook providing the application's authentication context
 *
 * @returns the application's authentication context
 */
export const useAuthContext = () => useContext(AuthContext);
