import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@nelnet/unifi-components-react';
import { ForbearanceInfoLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { Skeleton } from '@mui/material';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the forbearance info table
 */
const ForbearanceInfoDisplay = ({
    lenderInfos,
    loadingInfo,
    infoError,
}: ForbearanceMatrixInfoDisplayProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>Sublender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Offered
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Status
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Months Eligible
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Qualifying Details
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Additional Guidelines
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Documents Required
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Restart at Conversion
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenderInfos ? (
                lenderInfos.map((lender) =>
                    lender.data.map((subLender) => (
                        <TableRow
                            key={`${lender.lenderId}-${subLender.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.sublender}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.offered}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                <Typography component="ul" fontSize="inherit">
                                    {subLender.status
                                        ? subLender.status.map((stat) => (
                                              <Typography
                                                  key={stat}
                                                  component="li"
                                                  fontSize="inherit"
                                              >
                                                  {stat}
                                              </Typography>
                                          ))
                                        : 'N/A'}
                                </Typography>
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.monthsEligible}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.qualifyingDetails}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.additionalGuidelines}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.documentsRequired}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {subLender.restartAtConversion}
                            </MatrixTableCell>
                        </TableRow>
                    ))
                )
            ) : (
                <></>
            )}
        </>
    );

    // Render the matrix on the frontend
    return (
        <>
            {infoError ? (
                <Typography textAlign="center">
                    There was an error getting data for this matrix
                </Typography>
            ) : loadingInfo ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rounded" width="100%" height="37.5vh" />
                </Box>
            ) : lenderInfos && lenderInfos.length > 0 ? (
                <Paper padding={0} style={{ width: '100%', overflow: 'auto' }}>
                    <TableContainer sx={{ maxHeight: '80vh' }}>
                        <Table
                            size="medium"
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead>
                                <TableHeaders />
                            </TableHead>
                            <TableBody>
                                <TableRows />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Typography textAlign="center">
                    No matrix data was found
                </Typography>
            )}
        </>
    );
};

/** Props for ForbearanceMatrixInfoDisplay component */
type ForbearanceMatrixInfoDisplayProps = {
    /** An array of ForbearanceInfoLender, for displaying on the frontend */
    lenderInfos?: ForbearanceInfoLender[];

    /** A boolean to check if the loading data is done or not */
    loadingInfo: boolean;

    /** A boolean to check if there's an error occuring while retrieving the data */
    infoError: boolean;
};

export default ForbearanceInfoDisplay;
