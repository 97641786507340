import { AppBar, Box, Toolbar, useTheme } from '@nelnet/unifi-components-react';
import { ReactComponent as Logo } from '../images/nelnet-logo-white.svg';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuthContext } from '../contexts/auth-context';
import MatricesTab from './matrices-tab';

/**
 * Component that displays the application navigation bar.
 */
export const NavBar = () => {
    const theme = useTheme();
    const { unauthenticate } = useAuthContext();

    return (
        <Box sx={{ flexgrow: 1 }}>
            <AppBar
                color="secondary"
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    bgcolor: theme.palette.secondary.dark,
                }}
            >
                <Toolbar>
                    <Box sx={{ flexgrow: 1 }}>
                        <Link to="/">
                            <Box height={theme.spacing(5)}>
                                <Logo />
                            </Box>
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            marginLeft: 'auto',
                            borderRadius: 0,
                        }}
                    >
                        <Button
                            component={Link}
                            to="/lenders"
                            color="inherit"
                            size="small"
                        >
                            Lenders
                        </Button>

                        <MatricesTab />

                        <Button
                            component={Link}
                            size="small"
                            to="/"
                            color="inherit"
                            onClick={unauthenticate}
                        >
                            Log Out
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default NavBar;
