/** The URL of the deployed backend */
const baseURL = 'https://development.nelnet.io/lenderreqapi/';

/** The ID of the AWS user pool */
const userPoolId = 'us-west-2_WHSE88shs';

/** The ID of the AWS app client */
const appClientId = '7lq6b7b3d1fhtclfmncjqdubqh';

/**
 * Function that returns the base URL for the Clarity API
 */
export function getAPIBaseURL() {
    return baseURL;
}

/**
 * Function that returns the user pool ID
 */
export function getUserPoolId() {
    return userPoolId;
}

/**
 * Function that returns the app client ID
 */
export function getAppClientId() {
    return appClientId;
}
