import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/auth-context';
import { getAPIBaseURL } from '../../env';
import { isInvalidTokenStatusCode } from '../../utils';
import {
    Button,
    ButtonGroup,
    Typography,
} from '@nelnet/unifi-components-react';
import {
    ForbearanceApplications,
    ForbearanceInfoLender,
    ForbearanceNoteLender,
} from '../../models';
import ForbearanceInfoDisplay from './forbearance-info-display';
import ForbearanceNoteTemplateDisplay from './forbearance-note-template-display';
import ForbearanceApplicationDisplay from './forbearance-application-display';

/**
 * The index page for forbearance where it handles getting the data from the endpoint to display for the user
 */
const ForbearanceMatrixPage = () => {
    // Retrieve the base URL
    const baseUrl = getAPIBaseURL();

    // Retrieve the authentication token from the auth-context
    const { token, unauthenticate } = useAuthContext();

    const [selectedTab, setSelectedTab] = useState(0);

    // The error for handling getting data from the endpoints correposponding to different forbearance matrics
    const [infoError, setInfoError] = useState(false);
    const [noteError, setNoteError] = useState(false);
    const [appError, setAppError] = useState(false);

    // The loading states for the different forbearance matrices
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [loadingNote, setLoadingNote] = useState(true);
    const [loadingApp, setLoadingApp] = useState(true);

    // The data for the different forbearance matrices
    const [lenderInfos, setLenderInfos] = useState<ForbearanceInfoLender[]>();
    const [lenderNotes, setLenderNotes] = useState<ForbearanceNoteLender[]>();
    const [applicationData, setApplicationData] =
        useState<ForbearanceApplications>();

    // Hook to fetch the forbearance matrix data from the server when the component mounts or the token changes
    useEffect(() => {
        setLoadingInfo(true);
        axios
            .get<ForbearanceInfoLender[]>(
                `${baseUrl}matrices/forbearance/info`,
                {
                    headers: {
                        authorization: `bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                // If the data is retrieved, set the response data for the matrix data, and set the error and loading state to false
                setLenderInfos(response.data);
                setInfoError(false);
                setLoadingInfo(false);
            })
            .catch((error) => {
                // If there is an error, check if it's an invalid token, and unauthenticate it
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    // Otherwise, set the error to true and loading state to false
                    setInfoError(true);
                    setLoadingInfo(false);
                }
            });
    }, []);

    // Hook to fetch the forbearance note data from the server when the component mounts or the token changes
    useEffect(() => {
        setLoadingNote(true);
        axios
            .get<ForbearanceNoteLender[]>(
                `${baseUrl}matrices/forbearance/note-templates`,
                {
                    headers: {
                        authorization: `bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                // If the data is retrieved, set the response data for the matrix data, and set the error and loading state to false
                setLenderNotes(response.data);
                setNoteError(false);
                setLoadingNote(false);
            })
            .catch((error) => {
                // If there is an error, check if it's an invalid token, and unauthenticate it
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    // Otherwise, set the error to true and loading state to false
                    setNoteError(true);
                    setLoadingNote(false);
                }
            });
    }, []);

    // Hook to fetch the forbearance app data from the server when the component mounts or the token changes
    useEffect(() => {
        setLoadingApp(true);
        axios
            .get<ForbearanceApplications>(
                `${baseUrl}matrices/forbearance/applications`,
                {
                    headers: {
                        authorization: `bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                // If the data is retrieved, set the response data for the matrix data, and set the error and loading state to false
                setApplicationData(response.data);
                setAppError(false);
                setLoadingApp(false);
            })
            .catch((error) => {
                // If there is an error, check if it's an invalid token, and unauthenticate it
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    // Otherwise, set the error to true and loading state to false
                    setAppError(true);
                    setLoadingApp(false);
                }
            });
    }, []);

    return (
        <>
            <Typography mb={4} variant="h1" textAlign="center">
                Forbearance
            </Typography>
            <ButtonGroup size="small" sx={{ mb: 3 }}>
                <Button
                    active={selectedTab === 0}
                    onClick={() => setSelectedTab(0)}
                >
                    Forbearance Info
                </Button>
                <Button
                    active={selectedTab === 1}
                    onClick={() => setSelectedTab(1)}
                >
                    Note Template
                </Button>
                <Button
                    active={selectedTab === 2}
                    onClick={() => setSelectedTab(2)}
                >
                    Forbearance Applications
                </Button>
            </ButtonGroup>

            {selectedTab === 0 ? (
                <ForbearanceInfoDisplay
                    lenderInfos={lenderInfos}
                    loadingInfo={loadingInfo}
                    infoError={infoError}
                />
            ) : selectedTab === 1 ? (
                <ForbearanceNoteTemplateDisplay
                    lenderNotes={lenderNotes}
                    loadingNote={loadingNote}
                    noteError={noteError}
                />
            ) : selectedTab === 2 ? (
                <ForbearanceApplicationDisplay
                    applicationData={applicationData}
                    loadingApp={loadingApp}
                    appError={appError}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default ForbearanceMatrixPage;
