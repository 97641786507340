import { Button, Skeleton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@nelnet/unifi-components-react';
import { LoanProgram } from '../../../models';
import LoanProgramHeader from './loan-program-header';

/**
 * Component that handles any errors that occur in the display of loan
 * program sections on the loan program page
 */
export default class LoanProgramErrorBoundary extends React.Component<
    LoanProgramErrorBoundaryProps,
    LoanProgramErrorBoundaryState
> {
    // NOTE: This component is a class component rather than a functional component
    // because React does not yet support creating error boundaries as functional
    // components.

    /**
     * Initializes the error boundary
     *
     * @param props the properties to pass into the component
     */
    constructor(props: LoanProgramErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * Sets the component's state to indicate that an error has occured
     *
     * @returns the updated state
     */
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    /**
     * Catches exceptions generated in children components
     */
    componentDidCatch() {
        console.warn(
            'The following loan program could not be displayed:',
            this.props.loanProgram
        );
    }

    /**
     * Renders the component
     *
     * @returns the rendered component
     */
    render() {
        if (this.state.hasError) {
            // If an error has occurred, render the fallback display
            return (
                <>
                    <Button
                        startIcon={<ArrowBackIosNewIcon />}
                        component={Link}
                        to="/lenders"
                        color="grey"
                        size="small"
                    >
                        Back
                    </Button>

                    <LoanProgramHeader loanProgram={this.props.loanProgram} />

                    {this.props.loanProgram ? (
                        <>
                            <Typography gutterBottom>
                                Unfortunately, this loan program is formatted in
                                a way that is not supported by Clarity. Here is
                                the raw data:
                            </Typography>

                            <Paper
                                variant="outlined"
                                padding={2}
                                sx={{ overflowX: 'scroll' }}
                            >
                                <Typography
                                    component="pre"
                                    fontFamily="monospace"
                                >
                                    {JSON.stringify(
                                        this.props.loanProgram,
                                        null,
                                        2
                                    )}
                                </Typography>
                            </Paper>
                        </>
                    ) : (
                        <Skeleton
                            width="100%"
                            height="37.5vh"
                            variant="rounded"
                        />
                    )}
                </>
            );
        } else {
            // If no error has occurred, render the loan program page's data
            return this.props.children;
        }
    }
}

/**
 * Props for the LoanProgramErrorBoundary component
 */
type LoanProgramErrorBoundaryProps = {
    /** The loan program being displayed on the loan program page */
    loanProgram?: LoanProgram;

    /** The components to try to render on the loan program page unless an error occurs */
    children?: React.ReactNode;
};

/**
 * State for the LoanProgramErrorBoundary component
 */
type LoanProgramErrorBoundaryState = {
    /** Whether the error boundary has encountered an error within its components */
    hasError: boolean;
};
