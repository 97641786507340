import {
    Panel,
    PanelHeader,
    PanelContent,
    List,
    ListItem,
    ListItemText,
    useTheme,
} from '@nelnet/unifi-components-react';
import { Link } from 'react-router-dom';
import { Lender } from '../../../models';

/**
 * Component that displays a panel of lenders and loan programs
 */
const LenderPanel = ({ lender, isExpanded }: LenderPanelProps) => {
    const theme = useTheme();

    return (
        <Panel
            elevation={0}
            enableCollapse
            expanded={isExpanded}
            sx={{ borderRadius: 0, padding: 0 }}
        >
            <PanelHeader
                title={lender.name}
                titleComponent="h2"
                style={{
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                    borderRadius: 0,
                }}
            />

            <PanelContent style={{ padding: 0 }}>
                {lender.loanPrograms.map((loanProgram, index) => (
                    <List key={index} disablePadding>
                        <ListItem sx={{ padding: 0 }}>
                            <Link
                                to={`/loan-program/${loanProgram.loanProgramId}`}
                            >
                                <ListItemText
                                    primary={
                                        loanProgram.description ??
                                        'Loan Program'
                                    }
                                    secondary={loanProgram.loanProgramNumber}
                                />
                            </Link>
                        </ListItem>
                    </List>
                ))}
            </PanelContent>
        </Panel>
    );
};

/**
 * Props for the LenderPanel component
 */
type LenderPanelProps = {
    /** The lender for which to display data */
    lender: Lender;

    /** Whether the panel has been expanded */
    isExpanded: boolean;
};

export default LenderPanel;
