import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { IncentivesSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Incentives section of the loan program page
 */
const Incentives = ({ loanProgram, isEditing, onEdit }: IncentivesProps) => {
    /**
     * Helper function to call when any data in the Incentives section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<IncentivesSection>) =>
        onEdit({
            incentives: {
                ...loanProgram.incentives,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Auto Debit Incentive</Typography>
            <FieldData label="Auto Debit Incentive">
                {loanProgram.incentives.autoDebit?.incentive}
            </FieldData>
            <FieldData label="Additional Auto Debit Incentive Details">
                {!isEditing ? (
                    loanProgram.incentives.autoDebit?.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Auto Debit Incentive Details"
                        id="additional-auto-debit-incentive-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.incentives.autoDebit?.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                autoDebit: {
                                    ...loanProgram.incentives.autoDebit,
                                    details: event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">On Time Payment</Typography>
            <FieldData label="On Time Payment Incentive Details">
                {!isEditing ? (
                    loanProgram.incentives.onTimePaymentIncentiveDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="On Time Payment Incentive Details"
                        id="on-time-payment-incentive-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.incentives
                                .onTimePaymentIncentiveDetails ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                onTimePaymentIncentiveDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Additional Incentives</Typography>
            <FieldData label="Additional Incentive Details">
                {!isEditing ? (
                    loanProgram.incentives.additionalIncentiveDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Incentive Details"
                        id="additional-incentive-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.incentives.additionalIncentiveDetails ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalIncentiveDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Incentives;

/** Props for the Incentives component */
type IncentivesProps = Required<LoanProgramDataProps>;
