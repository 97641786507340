import { ErrorOutline } from '@mui/icons-material';
import { Box, darken, lighten } from '@mui/material';
import { Grid, Typography, useTheme } from '@nelnet/unifi-components-react';

/**
 * Component that displays an alert with a specified color and text.
 * @param {AlertProps} props - The props that are passed to the component.
 */
const Alert = (props: AlertProps) => {
    const theme = useTheme();

    return (
        <Box
            padding={1}
            bgcolor={lighten(theme.palette[props.color].main, 0.85)}
            color={darken(theme.palette[props.color].main, 0.5)}
            borderRadius={1}
            width="100%"
        >
            <Grid container gap={2}>
                <Grid item>
                    <ErrorOutline color={props.color} />
                </Grid>
                <Grid item xs>
                    <Typography>{props.text}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

/**
 * Props for the Alert component.
 * @typedef {Object} AlertProps
 * @property {string} text - The text to display in the alert.
 * @property {string} color - The color of the alert.
 */
type AlertProps = {
    text: string;
    color: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
};

export default Alert;
