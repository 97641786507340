import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { DeathSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Death section of the loan program page
 */
const Death = ({ loanProgram, isEditing, onEdit }: DeathProps) => {
    /**
     * Helper function to call when any data in the Death section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<DeathSection>) =>
        onEdit({
            death: {
                ...loanProgram.death,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Required Documentation</Typography>
            <FieldData label="Required Documentation">
                {!isEditing ? (
                    loanProgram.death.requiredDocumentation
                ) : (
                    <TextField
                        value={loanProgram.death.requiredDocumentation ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                requiredDocumentation:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Required Documentation"
                        id="required-documentation"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3">Borrower Death</Typography>
            <FieldData label="Borrower Death Description">
                {!isEditing ? (
                    loanProgram.death.borrowerDeathDescription
                ) : (
                    <TextField
                        value={loanProgram.death.borrowerDeathDescription ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                borrowerDeathDescription:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Borrower Death Description"
                        id="borrower-death-description"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3">Co-borrower/Co-signer Death</Typography>
            <FieldData label="Co-borrower/Co-signer Death Description">
                {!isEditing ? (
                    loanProgram.death.coborrowerOrCosignerDeathDescription
                ) : (
                    <TextField
                        value={
                            loanProgram.death
                                .coborrowerOrCosignerDeathDescription ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                coborrowerOrCosignerDeathDescription:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Co-borrower/Co-signer Death Description"
                        id="co-borrower-or-co-signer-death-description"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3">Payments</Typography>
            <FieldData label="Are payments refunded after the date of death?">
                {!isEditing ? (
                    loanProgram.death.arePaymentsRefundedAfterDeath
                ) : (
                    <RadioGroup
                        row
                        name="payments-refunded-after-death"
                        value={
                            loanProgram.death.arePaymentsRefundedAfterDeath ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                arePaymentsRefundedAfterDeath:
                                    event.target.value,
                            })
                        }
                    >
                        <FormControlLabel
                            value="Yes"
                            label="Yes"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="No"
                            label="No"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="Upon Request"
                            label="Upon Request"
                            control={<Radio />}
                        />
                    </RadioGroup>
                )}
            </FieldData>

            <Typography variant="h3">Death Process Details</Typography>
            <FieldData label="Process while awaiting documentation">
                {!isEditing ? (
                    loanProgram.death.deathProcessDetails
                        ?.processWhileAwaitingDocumentation
                ) : (
                    <TextField
                        value={
                            loanProgram.death.deathProcessDetails
                                ?.processWhileAwaitingDocumentation ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                deathProcessDetails: {
                                    ...loanProgram.death.deathProcessDetails,
                                    processWhileAwaitingDocumentation:
                                        event.target.value || undefined,
                                },
                            })
                        }
                        fullWidth
                        placeholder="Process while awaiting documentation"
                        id="process-while-awaiting-documentation"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <FieldData label="Process after documentation is received">
                {!isEditing ? (
                    loanProgram.death.deathProcessDetails
                        ?.processAfterDocumentationReceived
                ) : (
                    <TextField
                        value={
                            loanProgram.death.deathProcessDetails
                                ?.processAfterDocumentationReceived ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                deathProcessDetails: {
                                    ...loanProgram.death.deathProcessDetails,
                                    processAfterDocumentationReceived:
                                        event.target.value || undefined,
                                },
                            })
                        }
                        fullWidth
                        placeholder="Process after documentation is received"
                        id="process-after-documentation-is-received"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3">Agent Phone Steps</Typography>
            <FieldData label="Steps for Agent to Follow While on the Phone">
                {!isEditing ? (
                    loanProgram.death.agentPhoneSteps
                ) : (
                    <TextField
                        value={loanProgram.death.agentPhoneSteps ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                agentPhoneSteps:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Steps for Agent to Follow While on the Phone"
                        id="steps-to-follow-on-phone"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3">
                Additional Death Process Details
            </Typography>
            <FieldData label="Additional Death Process Details">
                {!isEditing ? (
                    loanProgram.death.additionalDeathProcessDetails
                ) : (
                    <TextField
                        value={
                            loanProgram.death.additionalDeathProcessDetails ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDeathProcessDetails:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Additional Death Process Details"
                        id="additional-death-process-details"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>
        </>
    );
};

export default Death;

/** Props for the Death component */
type DeathProps = Required<LoanProgramDataProps>;
