import {
    Drawer,
    Link as MuiLink,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    useTheme,
} from '@nelnet/unifi-components-react';
import { Link } from 'react-router-dom';
import { compareAlphabetically } from '../utils';

/**
 * A functional component that renders a side navigation drawer with a list of navigation items.
 * The navigation items include internal and external links.
 */
const SideNav = () => {
    const theme = useTheme();

    const drawerBackgroundColor = theme.palette.grey[800];
    const interactedTabBackgroundColor = theme.palette.grey[700];

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Toolbar />
            <List
                aria-label="options"
                sx={{
                    backgroundColor: drawerBackgroundColor,
                    color: theme.palette.getContrastText(drawerBackgroundColor),
                }}
            >
                {[
                    ...topSideNavItems,
                    ...additionalSideNavItems.sort((a, b) =>
                        compareAlphabetically(a.name, b.name)
                    ),
                ].map((item, index) => (
                    // Use React Router <Link> for internal links and <a> for external links

                    <ListItem
                        key={index}
                        sx={{
                            '& a:hover': {
                                backgroundColor: interactedTabBackgroundColor,
                            },
                        }}
                        disablePadding
                    >
                        {['/', '#'].includes(item.link.charAt(0)) ? (
                            <Link to={item.link}>
                                <ListItemText
                                    primary={item.name}
                                    sx={{ paddingY: 1 }}
                                />
                            </Link>
                        ) : (
                            <MuiLink
                                href={item.link}
                                target="_blank"
                                underline="none"
                                rel="noopener noreferrer"
                            >
                                <ListItemText
                                    primary={item.name}
                                    sx={{ paddingY: 1 }}
                                />
                            </MuiLink>
                        )}
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

const drawerWidth = 300;

const topSideNavItems = [
    {
        name: 'Lenders',
        link: '/lenders',
    },
];

const additionalSideNavItems = [
    {
        name: 'Firstmark Home',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark',
    },
    {
        name: 'CC Call Scripts',
        link: 'https://nelnet.sharepoint.com/:w:/r/teams/Firstmark/_layouts/15/Doc.aspx?sourcedoc=%7B8A7C6681-E426-46CA-98BC-B65B2C0D2663%7D&file=Call%20Script%20Job%20Aid.docx&_DSL=1&action=default&mobileredirect=true&isSPOFile=1',
    },
    {
        name: 'Velocity eForms',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Firstmark-Velocity-eForms.aspx?csf=1&web=1&e=Uaqgmr',
    },
    {
        name: 'Firstmark Internal Contact List',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/Lists/Firstmark%20Internal%20Contact%20List/AllItems.aspx',
    },
    {
        name: 'Floor Communications',
        link: 'https://nelnet.sharepoint.com/teams/NDSBusinessLineCommunications',
    },
    {
        name: 'Quickmailer',
        link: 'https://nelnet.myappcenter.biz/Quickmailer/',
    },
    {
        name: 'Lender Contacts',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Lender-Contacts.aspx',
    },
    {
        name: 'Processing Resources',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Processing-Resources.aspx',
    },
    {
        name: 'Contact Center Resources',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Contact-Center-Resources.aspx',
    },
    {
        name: 'Correspondence Team Resources',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Correspondence-Team-Hub.aspx',
    },
    {
        name: 'Loan Origination Resources',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/SitePages/Processing-Resources.aspx',
    },
    {
        name: 'Analyst Resources',
        link: 'https://nelnet.sharepoint.com/teams/Firstmark/FM%20Processing%20Procedures/Forms/Analyst%20Resources.aspx',
    },
    {
        name: 'Global Training and Development Hub',
        link: 'https://nelnet.sharepoint.com/teams/GlobalTraining',
    },
];

export default SideNav;
