import { useTheme, Divider, Grid, Paper } from '@nelnet/unifi-components-react';
import { lighten } from '@mui/material';
import { useEffect, useState } from 'react';
import LenderPanel from './lender-panel';
import { Lender, LoanProgram } from '../../../models';

/**
 * Component for the filtering and display of lenders' data on the Lender page
 */
const LenderData = ({ searchInput, lenders }: LenderDataProps) => {
    const theme = useTheme();

    const [filteredLenders, setFilteredLenders] = useState<Lender[]>([]);

    /**
     * Checks whether a lender name contains the search input
     *
     * @param lenderName the name of the lender
     * @returns true if the lender name contains the search input or false otherwise
     */
    const lenderNameMatchesSearch = (lenderName: string) => {
        return lenderName.toLowerCase().includes(searchInput.toLowerCase());
    };

    /**
     * Filters a list of loan programs to only those that match the search input
     *
     * @param loanPrograms the list of loan programs to compare to the search input
     * @returns
     */
    const getLoanProgramsMatchingSearch = (loanPrograms: LoanProgram[]) => {
        if (searchInput.length === 0) {
            // If the search input is empty, all loan programs match the search input
            return loanPrograms;
        } else {
            return loanPrograms.filter(
                (loanProgram) =>
                    // Loan program description matches the search input
                    (loanProgram.description &&
                        loanProgram.description
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())) ||
                    // Loan program number matches the search input
                    (loanProgram.loanProgramNumber &&
                        loanProgram.loanProgramNumber
                            .toLowerCase()
                            .includes(searchInput.toLowerCase()))
            );
        }
    };

    /**
     * Filter the lenders and loan programs displayed on the page
     */
    const filterDisplayedData = () => {
        if (searchInput.length === 0) {
            // If the search input is empty, show all lenders with all loan programs
            setFilteredLenders(lenders);
        } else {
            const matchingLenders: Lender[] = [];

            // Filter displayed lenders by (1) if the lender matches the search and
            // (2) if the loan program matches the search
            lenders.forEach((lender) => {
                if (lenderNameMatchesSearch(lender.name)) {
                    matchingLenders.push(lender);
                } else {
                    const matchingLoanPrograms = getLoanProgramsMatchingSearch(
                        lender.loanPrograms
                    );

                    if (matchingLoanPrograms.length > 0) {
                        const filteredLender = { ...lender };
                        filteredLender.loanPrograms = matchingLoanPrograms;
                        matchingLenders.push(filteredLender);
                    }
                }
            });

            setFilteredLenders(matchingLenders);
        }
    };

    // When the search input changes, re-filter the displayed data
    useEffect(() => {
        filterDisplayedData();
    }, [searchInput]);

    // Cut the filteredLenders list in half to display two columns, and
    // render each column
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper square>
                    {filteredLenders
                        .slice(0, Math.ceil(filteredLenders.length / 2))
                        .map((lender, index) => (
                            <Grid item key={lender.lenderId}>
                                <LenderPanel
                                    lender={lender}
                                    isExpanded={
                                        searchInput === ''
                                            ? false
                                            : !lenderNameMatchesSearch(
                                                  lender.name
                                              )
                                    }
                                />
                                {index <
                                    Math.ceil(filteredLenders.length / 2) -
                                        1 && (
                                    <Divider
                                        sx={{
                                            borderColor: lighten(
                                                theme.palette.secondary.dark,
                                                0.4
                                            ),
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper square>
                    {filteredLenders
                        .slice(Math.ceil(filteredLenders.length / 2))
                        .map((lender, index) => (
                            <Grid item key={lender.lenderId}>
                                <LenderPanel
                                    lender={lender}
                                    isExpanded={
                                        searchInput === ''
                                            ? false
                                            : !lenderNameMatchesSearch(
                                                  lender.name
                                              )
                                    }
                                />
                                {index <
                                    filteredLenders.length -
                                        Math.ceil(filteredLenders.length / 2) -
                                        1 && (
                                    <Divider
                                        sx={{
                                            borderColor: lighten(
                                                theme.palette.secondary.dark,
                                                0.4
                                            ),
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                </Paper>
            </Grid>
        </Grid>
    );
};

/** Props for the LenderData component */
type LenderDataProps = {
    /** The list of lenders to display */
    lenders: Lender[];

    /** The user's search */
    searchInput: string;
};

export default LenderData;
