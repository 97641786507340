import { Box, Typography } from '@nelnet/unifi-components-react';
import { ReactNode } from 'react';
import MarkdownFormatter from './markdown-formatter';
import { formatBooleanAsText } from '../utils';

/**
 * Component that displays a label with numerical, text, or other data
 */
const FieldData = (props: FieldDataProps) => {
    // Format the data under the label
    let children: ReactNode;
    if (
        props.children === undefined ||
        props.children === null ||
        props.children === '' ||
        (Array.isArray(props.children) && props.children.length === 0)
    ) {
        // If no data was provided, show empty field text
        children = (
            <Typography>
                {props.emptyFieldText || 'No information provided'}
            </Typography>
        );
    } else if (typeof props.children === 'number') {
        // For numerical data, display the number
        children = <Typography>{props.children}</Typography>;
    } else if (typeof props.children === 'string') {
        // For text data, format with Markdown
        children = <MarkdownFormatter>{props.children}</MarkdownFormatter>;
    } else if (typeof props.children === 'boolean') {
        // For boolean data, display the boolean as text
        children = (
            <Typography>{formatBooleanAsText(props.children)}</Typography>
        );
    } else {
        // For all other types of data, display the data as provided
        children = props.children;
    }

    return (
        <Box marginY={1.5}>
            <Typography variant="subtitle2" fontWeight="bold">
                {props.label}
            </Typography>
            {children}
        </Box>
    );
};
export default FieldData;

/**
 * Props for the Field Data component
 */
type FieldDataProps = {
    /** The label above the data in the component */
    label: string;

    /** Text to display if the component receives empty data */
    emptyFieldText?: string;

    /** Data to show in the component */
    children?: ReactNode;
};
