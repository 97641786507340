import { Container } from '@mui/system';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAPIBaseURL } from '../../env';
import {
    Typography,
    Box,
    Grid,
} from '@nelnet/unifi-components-react';
import LenderData from './components/lender-data';
import { useAuthContext } from '../../contexts/auth-context';
import { isInvalidTokenStatusCode } from '../../utils';
import SearchBar from '../../components/search-bar';
import { Lender } from '../../models';
import { Skeleton } from '@mui/material';

/**
 * Component for the Lender page that shows all lenders and their
 * available loan programs
 */
const LenderPage = () => {
    const { token, unauthenticate } = useAuthContext();

    // Text that the user has entered into the search bar
    const [searchInput, setSearchInput] = useState('');

    const baseUrl = getAPIBaseURL();

    // States for fetching lenders data
    const [lenders, setLenders] = useState<Lender[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Fetch lenders data
    useEffect(() => {
        setLoading(true);
        axios
            .get<Lender[]>(`${baseUrl}lenders`, {
                headers: {
                    authorization: `bearer ${token}`,
                },
            })
            .then((response) => {
                setLenders(response.data);
                setError(false);
                setLoading(false);
            })
            .catch((error) => {
                if (isInvalidTokenStatusCode(error.response?.status)) {
                    unauthenticate();
                } else {
                    setError(true);
                    setLoading(false);
                }
            });
    }, []);

    return (
        <Container>
            <Typography variant="h1" textAlign="center" gutterBottom={!error}>
                Lenders
            </Typography>
            <Grid sx={{ mb: 3 }}>
                <SearchBar
                    label="Search for lenders or loan programs"
                    disabled={loading}
                    searchTerm={searchInput}
                    setSearchTerm={setSearchInput}
                    fullWidth
                />
            </Grid>
            {error ? (
                <Typography textAlign="center">
                    There was an error getting lenders data
                </Typography>
            ) : loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="rounded" width="100%" height="37.5vh" />
                </Box>
            ) : lenders ? (
                <>
                    <LenderData searchInput={searchInput} lenders={lenders} />
                </>
            ) : (
                <></>
            )}
        </Container>
    );
};

export default LenderPage;
