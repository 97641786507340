import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { StatementsSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Statements section of the loan program page
 */
const Statements = ({ loanProgram, isEditing, onEdit }: StatementsProps) => {
    /**
     * Helper function to call when any data in the Statements section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<StatementsSection>) =>
        onEdit({
            statementsInformation: {
                ...loanProgram.statementsInformation,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Number of days prior to the due date that the statement will generate">
                {
                    loanProgram.statementsInformation
                        .numDaysPriorToDueDateToGenerateStatement
                }
            </FieldData>
            <FieldData label="Billing Frequency">
                {loanProgram.statementsInformation.billingFrequency}
            </FieldData>
            <FieldData label="Additional Details">
                {!isEditing ? (
                    loanProgram.statementsInformation.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Details"
                        id="additional-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.statementsInformation.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Statements;

/** Props for the ServicingLoanProgramInformation component */
type StatementsProps = Required<LoanProgramDataProps>;
