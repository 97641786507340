import { Link, Typography } from '@nelnet/unifi-components-react';
import FieldData from '../../../../components/field-data';
import Address from '../../../../components/address';
import { LoanProgram } from '../../../../models';

/**
 * Component for the Lender Information section of the loan program page
 */
const LenderInformation = ({ loanProgram }: LenderInformationProps) => {
    // Filter out undefined phone numbers
    const phoneNumbers = loanProgram.lenderInformation.phoneNumbers.filter(
        ({ phoneNumber }) => phoneNumber !== undefined && phoneNumber.length > 0
    );

    return (
        <>
            <FieldData label="Lender">
                {loanProgram.lenderInformation.lenderName}
            </FieldData>

            <FieldData label="Lender Number">
                {loanProgram.lenderInformation.lenderNumber}
            </FieldData>

            <FieldData label="Website" emptyFieldText="No website provided">
                {loanProgram.lenderInformation.lenderWebsiteAddress && (
                    <Typography>
                        <Link
                            href={`http://${loanProgram.lenderInformation.lenderWebsiteAddress}`}
                        >
                            {loanProgram.lenderInformation.lenderWebsiteAddress}
                        </Link>
                    </Typography>
                )}
            </FieldData>

            <FieldData
                label={
                    loanProgram.lenderInformation.lenderStreetAddresses
                        .length === 1
                        ? 'Address'
                        : 'Addresses'
                }
                emptyFieldText="No addresses provided"
            >
                {loanProgram.lenderInformation.lenderStreetAddresses &&
                    (loanProgram.lenderInformation.lenderStreetAddresses
                        .length === 0 ? undefined : loanProgram
                          .lenderInformation.lenderStreetAddresses.length ===
                      1 ? (
                        <Address
                            value={
                                loanProgram.lenderInformation
                                    .lenderStreetAddresses[0]
                            }
                        />
                    ) : (
                        <ul style={{ margin: 0 }}>
                            {loanProgram.lenderInformation.lenderStreetAddresses.map(
                                (address) => (
                                    <li
                                        key={`${address.street1}, ${address.street2}, ${address.city}, ${address.state}, ${address.countryCode}, ${address.postalCode}`}
                                    >
                                        <Address value={address} />
                                    </li>
                                )
                            )}
                        </ul>
                    ))}
            </FieldData>

            <FieldData
                label={
                    phoneNumbers.length === 1 ? 'Phone Number' : 'Phone Numbers'
                }
                emptyFieldText="No phone numbers provided"
            >
                {phoneNumbers.length > 0
                    ? phoneNumbers.map(({ phoneNumber }) => (
                          <Typography key={phoneNumber}>
                              {phoneNumber}
                          </Typography>
                      ))
                    : undefined}
            </FieldData>
        </>
    );
};

export default LenderInformation;

/**
 * Props for the LenderInformation component
 */
type LenderInformationProps = {
    /** The loan program for which to display lender information */
    loanProgram: LoanProgram;
};
