import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { SchoolClosingSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the School Closing section of the loan program page
 */
const SchoolClosing = ({
    loanProgram,
    isEditing,
    onEdit,
}: SchoolClosingProps) => {
    /**
     * Helper function to call when any data in the School Closing section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<SchoolClosingSection>) =>
        onEdit({
            schoolClosing: {
                ...loanProgram.schoolClosing,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="School Closure Details">
                {!isEditing ? (
                    loanProgram.schoolClosing.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="School Closure Details"
                        id="school-closure-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.schoolClosing.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Additional School Closure Details">
                {!isEditing ? (
                    loanProgram.schoolClosing.schoolClosureDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional School Closure Details"
                        id="additional-school-closure-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.schoolClosing.schoolClosureDetails ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                schoolClosureDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default SchoolClosing;

/** Props for the SchoolClosing component */
type SchoolClosingProps = Required<LoanProgramDataProps>;
