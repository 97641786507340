import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { DueDatesSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Due Dates section of the loan program page
 */
const DueDates = ({ loanProgram, isEditing, onEdit }: DueDatesProps) => {
    /**
     * Helper function to call when any data in the Due Dates section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<DueDatesSection>) =>
        onEdit({
            dueDatesInformation: {
                ...loanProgram.dueDatesInformation,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Advancing Due Date</Typography>
            <FieldData label="Due Date Advancement">
                {loanProgram.dueDatesInformation.dueDateAdvancement
                    ?.isUnlimited === true
                    ? 'Unlimited'
                    : loanProgram.dueDatesInformation.dueDateAdvancement
                          ?.numberOfBillingCycles}
            </FieldData>
            <FieldData label="Additional due date advancement details">
                {!isEditing ? (
                    loanProgram.dueDatesInformation
                        .additionalDueDateAdvancementDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional due date advancement details"
                        id="additional-due-date-advancement-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.dueDatesInformation
                                .additionalDueDateAdvancementDetails
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalDueDateAdvancementDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Changing Due Date</Typography>
            <FieldData label="Are due date changes allowed based on customer request">
                {loanProgram.dueDatesInformation.isDueDateChangeAllowed}
            </FieldData>
            <FieldData label="Additional Change Due Date Rules">
                {!isEditing ? (
                    loanProgram.dueDatesInformation.additionalChangeDueDateRules
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Change Due Date Rules"
                        id="additional-change-due-date-rules"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.dueDatesInformation
                                .additionalChangeDueDateRules
                        }
                        onChange={(event) =>
                            onEditSection({
                                additionalChangeDueDateRules:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Accounts on Auto Debit</Typography>
            <FieldData label="Accounts on Auto Debit Details">
                {!isEditing ? (
                    loanProgram.dueDatesInformation.accountsOnAutoDebitDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Accounts on Auto Debit Details"
                        id="accounts-on-auto-debit-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.dueDatesInformation
                                .accountsOnAutoDebitDetails
                        }
                        onChange={(event) =>
                            onEditSection({
                                accountsOnAutoDebitDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Aligning Due Dates</Typography>
            <FieldData label="Aligning Due Date">
                {!isEditing ? (
                    loanProgram.dueDatesInformation.aligningDueDateDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Aligning Due Date"
                        id="aligning-due-date"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.dueDatesInformation
                                .aligningDueDateDetails
                        }
                        onChange={(event) =>
                            onEditSection({
                                aligningDueDateDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Additional Due Date Details</Typography>
            <FieldData label="Additional Due Date Details">
                {!isEditing ? (
                    loanProgram.dueDatesInformation.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Due Date Details"
                        id="additional-due-date-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.dueDatesInformation.details}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default DueDates;

/** Props for the DueDates component */
type DueDatesProps = Required<LoanProgramDataProps>;
