import {
    Box,
    Divider,
    Panel,
    PanelContent,
    PanelHeader,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { Forbearance, LoanPeriodSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Loan Periods section of the loan program page
 */
const LoanPeriods = ({ loanProgram, isEditing, onEdit }: LoanPeriodsProps) => {
    /**
     * Helper function to call when any data in the Loan Periods section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<LoanPeriodSection>) =>
        onEdit({
            loanPeriod: {
                ...loanProgram.loanPeriod,
                ...data,
            },
        });

    /**
     * Helper function to call when a single forbearance in the list is edited
     *
     * @param data the forbearance data to update
     * @param forbearanceSettingId the ID of the forbearance to update
     */
    const onEditForbearance = (
        data: Partial<Forbearance>,
        forbearanceSettingId: string
    ) => {
        const indexToChange = loanProgram.loanPeriod.forbearances.findIndex(
            (forbearance) =>
                forbearance.forbearanceSettingId === forbearanceSettingId
        );

        const newForbearances = [...loanProgram.loanPeriod.forbearances];
        newForbearances[indexToChange] = {
            ...newForbearances[indexToChange],
            ...data,
        };

        onEditSection({ forbearances: newForbearances });
    };

    return (
        <>
            <Typography variant="h3">Repayment Loan Periods</Typography>
            <FieldData label="Originating Loan Periods">
                {!isEditing ? (
                    loanProgram.loanPeriod.originatingLoanPeriod
                ) : (
                    <TextField
                        value={
                            loanProgram.loanPeriod.originatingLoanPeriod ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                originatingLoanPeriod:
                                    event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Originating Loan Periods"
                        id="originating-loan-periods"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>

            <Typography variant="h3" gutterBottom>
                Payment Relief
            </Typography>
            {loanProgram.loanPeriod &&
            loanProgram.loanPeriod.forbearances.length > 0 ? (
                loanProgram.loanPeriod.forbearances.map(
                    (forbearance, index) => (
                        <Box
                            key={forbearance.forbearanceSettingId}
                            marginRight={-2}
                        >
                            <Panel elevation={0} enableCollapse>
                                <PanelHeader
                                    title={forbearance.name || 'Setting'}
                                    titleComponent="h3"
                                />

                                <PanelContent>
                                    <FieldData label="Type">
                                        {forbearance.type}
                                    </FieldData>

                                    <FieldData label="Length Per Instance">
                                        <Typography>
                                            <b>Minimum:</b>{' '}
                                            {forbearance.minBillingCyclesPerInstance ??
                                                'No minimum provided'}
                                        </Typography>
                                        <Typography>
                                            <b>Maximum:</b>{' '}
                                            {forbearance.maxBillingCyclesPerInstance ??
                                                'No maximum provided'}
                                        </Typography>
                                    </FieldData>

                                    <FieldData label="Maximum Time Allowed">
                                        {forbearance.maxBillingCyclesAllowed}
                                    </FieldData>

                                    <FieldData label="Is Loan Brought Current When Forbearance is Processed?">
                                        {forbearance.bringLoanCurrent}
                                    </FieldData>

                                    <FieldData label="Is the Loan Automatically Reamortized Once the Forbearance Expires?">
                                        {forbearance.reamRequired}
                                    </FieldData>

                                    <FieldData label="Is Interest Capitalized at the End of the Forbearance Period?">
                                        {forbearance.capInterest}
                                    </FieldData>

                                    <FieldData label="Is Term Extended for the Amount of Time the Forbearance is Processed?">
                                        {forbearance.extendTerm}
                                    </FieldData>

                                    <FieldData label="Are Fees Removed During the Forbearance Period (if Backdated)?">
                                        {forbearance.removeFees}
                                    </FieldData>

                                    <FieldData label="Is the Auto Debit Incentive Active During the Forbearance Period?">
                                        {forbearance.isAutoDebitActive}
                                    </FieldData>

                                    <FieldData label="Is the On Time Payment Incentive Active During the Forbearance Period?">
                                        {forbearance.onTimePayment}
                                    </FieldData>

                                    <FieldData label="Are loan modification active during the forbearance period?">
                                        {forbearance.areLoanModificationsActive}
                                    </FieldData>

                                    <FieldData label="Is Auto Debit Canceled During the Forbearance Period?">
                                        {forbearance.isAutoDebitCanceled}
                                    </FieldData>

                                    <FieldData label="Send Forbearance End Notification to the Customer?">
                                        {forbearance.sendWarningWhenEnding}
                                    </FieldData>

                                    <FieldData label="If an end notice is sent, how many days prior to the forbearance ending is the communication sent?">
                                        {
                                            forbearance.daysAdvanceWarningBeforeEnding
                                        }
                                    </FieldData>

                                    <FieldData label="Forbearance Qualification Details">
                                        {!isEditing ? (
                                            forbearance.qualificationDetails
                                        ) : (
                                            <TextField
                                                value={
                                                    forbearance.qualificationDetails ??
                                                    ''
                                                }
                                                onChange={(event) =>
                                                    onEditForbearance(
                                                        {
                                                            qualificationDetails:
                                                                event.target
                                                                    .value ||
                                                                undefined,
                                                        },
                                                        forbearance.forbearanceSettingId
                                                    )
                                                }
                                                fullWidth
                                                placeholder="Forbearance Qualification Details"
                                                id={`${forbearance.name}-qualification-details`}
                                                multiline
                                                minRows={multilineFieldHeight}
                                            />
                                        )}
                                    </FieldData>

                                    <FieldData label="Additional Loan Period Details">
                                        {!isEditing ? (
                                            forbearance.details
                                        ) : (
                                            <TextField
                                                value={
                                                    forbearance.details ?? ''
                                                }
                                                onChange={(event) =>
                                                    onEditForbearance(
                                                        {
                                                            details:
                                                                event.target
                                                                    .value ||
                                                                undefined,
                                                        },
                                                        forbearance.forbearanceSettingId
                                                    )
                                                }
                                                fullWidth
                                                placeholder="Additional Loan Period Details"
                                                id={`${forbearance.name}-additional-details`}
                                                multiline
                                                minRows={multilineFieldHeight}
                                            />
                                        )}
                                    </FieldData>
                                </PanelContent>
                            </Panel>
                            {index <
                                loanProgram.loanPeriod.forbearances.length -
                                    1 && <Divider />}
                        </Box>
                    )
                )
            ) : (
                <Typography paragraph>No information provided</Typography>
            )}
        </>
    );
};

export default LoanPeriods;

/** Props for the LoanPeriods component */
type LoanPeriodsProps = Required<LoanProgramDataProps>;
