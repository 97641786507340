import { Typography } from '@mui/material';
import { Grid } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { MailingAddress } from '../models';

/**
 * Component that renders an address form or displays an address as text.
 * @param {AddressProps} props - The props that are passed to the component.
 */
const Address = (props: AddressProps) => {
    const { id, value, isEditing, onChange } = props;
    const onChangeAddress = (newAddress: Partial<MailingAddress>) => {
        if (onChange) {
            const addressTemplate = {
                street1: '',
                city: '',
                state: '',
                countryCode: '',
                postalCode: '',
            };
            onChange({ ...addressTemplate, ...value, ...newAddress });
        }
    };

    return (
        <>
            {isEditing ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Street 1"
                            id={`${id}-street-1`}
                            value={value?.street1 ?? ''}
                            onChange={(event) =>
                                onChangeAddress({ street1: event.target.value || undefined})
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Street 2"
                            id={`${id}-street-2`}
                            value={value?.street2 ?? ''}
                            onChange={(event) =>
                                onChangeAddress({
                                    street2: event.target.value || undefined,
                                })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            placeholder="City"
                            id={`${id}-city`}
                            value={value?.city ?? ''}
                            onChange={(event) =>
                                onChangeAddress({
                                    city: event.target.value || undefined,
                                })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            placeholder="State"
                            id={`${id}-state`}
                            value={value?.state ?? ''}
                            onChange={(event) => {
                                onChangeAddress({
                                    state: event.target.value || undefined,
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            placeholder="Postal Code"
                            id={`${id}-postal-code`}
                            value={value?.postalCode ?? ''}
                            onChange={(event) => {
                                onChangeAddress({
                                    postalCode: event.target.value || undefined,
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            placeholder="Country Code"
                            id={`${id}-country-code`}
                            value={value?.countryCode ?? ''}
                            onChange={(event) => {
                                onChangeAddress({
                                    countryCode: event.target.value || undefined,
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            ) : value ? (
                <>
                    <Typography variant="body2">{value.street1}</Typography>
                    {value.street2 && (
                        <Typography variant="body2">{value.street2}</Typography>
                    )}
                    <Typography variant="body2">
                        {value.city}, {value.state} {value.postalCode}
                    </Typography>
                    <Typography variant="body2">{value.countryCode}</Typography>
                </>
            ) : (
                <Typography variant="body2">No address provided</Typography>
            )}
        </>
    );
};

/**
 * Props for the Address component.
 * @property {string} [id] - The unique ID of the component.
 * @property {MailingAddress} [value] - The mailing address to display or edit.
 * @property {boolean} [isEditing] - A flag that indicates whether the address form is being edited.
 * @property {function} [onChange] - A function that is called when the address form is edited.
 */
type AddressProps = {
    id?: string;
    value?: MailingAddress;
    isEditing?: boolean;
    onChange?: (address?: MailingAddress) => void;
};

export default Address;
