import {
    Box,
    Toolbar,
    UnifiThemeProvider,
    CssBaseline,
    createTheme,
} from '@nelnet/unifi-components-react';
import Routes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/nav-bar';
import SideNav from './components/side-nav';
import { useAuthContext } from './contexts/auth-context';
import LoginPage from './views/login-page';

// Use Unifi default theme
const theme = createTheme();

/**
 * The root component for the Clarity application
 */
export const App = () => {
    const { token } = useAuthContext();

    return (
        <Router>
            <UnifiThemeProvider theme={theme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    {token ? (
                        // If the user is authenticated, render the application
                        <>
                            <NavBar />
                            <SideNav />

                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    width: '100%',
                                    overflowX: 'auto',
                                }}
                            >
                                <Toolbar />

                                <Box padding={4}>
                                    <Routes />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        // Otherwise, render the Login page
                        <LoginPage />
                    )}
                </Box>
            </UnifiThemeProvider>
        </Router>
    );
};

export default App;
