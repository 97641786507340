import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Typography,
    useTheme,
} from '@nelnet/unifi-components-react';
import { tableHeadersSpacing } from '../../global';
import { ScraLender } from '../../models';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the scra guild lines table
 */
const ScraGuidelinesTable = ({ lenders }: ScraGuidelinesTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                How to Request
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Status(es) Available
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Maximum Time Allowed
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Military Deferment: Yes or No
            </TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Term Extension Allowed for Military Deferment or Forbearance
                Time Used: Yes or No
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}-${loanProgram.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.scraGuidelines?.howToRequest}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.scraGuidelines?.statusAvailable}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.scraGuidelines?.maxTimeAllowed
                                    ?.maxBillingCyclesAllowed &&
                                loanProgram.scraGuidelines?.maxTimeAllowed
                                    ?.maxBillingCyclesPerInstance ? (
                                    <>
                                        {
                                            loanProgram.scraGuidelines
                                                ?.maxTimeAllowed
                                                ?.maxBillingCyclesAllowed
                                        }{' '}
                                        month max, applied in{' '}
                                        {
                                            loanProgram.scraGuidelines
                                                ?.maxTimeAllowed
                                                ?.maxBillingCyclesPerInstance
                                        }{' '}
                                        month increments
                                    </>
                                ) : (
                                    'N/A'
                                )}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.scraGuidelines?.militDeferment}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.scraGuidelines
                                        ?.defermentOrForbearanceTimeUsed
                                }
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );

    // Render the matrix on the frontend
    return (
        <>
            <Paper style={{ width: '100%', overflow: 'auto' }}>
                <TableContainer sx={{ maxHeight: '80vh' }}>
                    <Table size="medium" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableHeaders />
                        </TableHead>
                        <TableBody>
                            <TableRows />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Typography mt={2}>
                **ATTN** Request must be received from servicemember or legal
                representative (POA) no later than 180 days after the
                servicemember has been released from military service.
            </Typography>
        </>
    );
};

/** Props for ScraGuidelinesTable component */
type ScraGuidelinesTableProps = {
    /** An array of ScraLender, for displaying on the frontend */
    lenders: ScraLender[];
};

export default ScraGuidelinesTable;
