import { Divider, Link, Typography } from '@nelnet/unifi-components-react';
import ReactMarkdown from 'react-markdown';

/**
 * Component that formats Markdown content using Unifi components
 */
const MarkdownFormatter = (props: MarkdownFormatterProps) => {
    const allowedElements = [
        'a',
        'br',
        'em',
        'hr',
        'li',
        'ol',
        'p',
        'strong',
        'ul',
    ];

    return (
        <ReactMarkdown
            allowedElements={allowedElements}
            unwrapDisallowed
            components={{
                // Map all displayed Markdown to Unifi components
                p: ({ children }) => (
                    <Typography fontSize="inherit" component="p" gutterBottom>
                        {children}
                    </Typography>
                ),
                ul: ({ children }) => (
                    <Typography fontSize="inherit" component="ul" gutterBottom>
                        {children}
                    </Typography>
                ),
                ol: ({ children }) => (
                    <Typography fontSize="inherit" component="ol" gutterBottom>
                        {children}
                    </Typography>
                ),
                li: ({ children }) => (
                    <Typography fontSize="inherit" component="li">
                        {children}
                    </Typography>
                ),
                a: ({ children, href, title }) => (
                    <Link href={href} title={title}>
                        {children}
                    </Link>
                ),
                hr: () => <Divider />,
            }}
        >
            {props.children}
        </ReactMarkdown>
    );
};

/**
 * Props for the MarkdownFormatter component
 */
type MarkdownFormatterProps = {
    /** The Markdown content to format */
    children: string;
};

export default MarkdownFormatter;
