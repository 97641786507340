import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    useTheme,
} from '@nelnet/unifi-components-react';
import { DeathLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the lender contacts table    
 */
const LenderContactsTable = ({ lenders }: LenderContactsTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);
    
    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Send Docs
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Contact Info
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Notes
            </TableCell>
        </TableRow>
    );
    
    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}${loanProgram.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.lenderContacts?.sendDocs}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.lenderContacts?.contactInfo}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.lenderContacts?.notes}
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );
    
    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for LenderContactsTable component */
interface LenderContactsTableProps {
    /** An array of DeathLender, for displaying on the frontend */
    lenders: DeathLender[];
}

export default LenderContactsTable;
