import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { PaymentSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Payments section of the loan program page
 */
const Payments = ({ loanProgram, isEditing, onEdit }: PaymentsProps) => {
    /**
     * Helper function to call when any data in the Payments section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<PaymentSection>) =>
        onEdit({
            payment: {
                ...loanProgram.payment,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Payment Settings</Typography>
            <FieldData label="Minimum Payment Amount">
                {loanProgram.payment.minPaymentAmount}
            </FieldData>
            <FieldData label="Payment Application">
                {loanProgram.payment.paymentApplication}
            </FieldData>
            <FieldData label="Payment Tolerance Amount">
                {loanProgram.payment.paymentToleranceAmount}
            </FieldData>
            <FieldData label="Payment Tolerance Type">
                {loanProgram.payment.paymentToleranceAmountType}
            </FieldData>
            <FieldData label="Number of days after loan is paid in full before paid in full communication is sent">
                {loanProgram.payment.paymentAgingDays}
            </FieldData>
            <FieldData label="Small Balance write off amount">
                {loanProgram.payment.smallBalanceWriteOffAmount}
            </FieldData>
            <FieldData label="Small Balance write up amount">
                {loanProgram.payment.smallBalanceWriteUpAmount}
            </FieldData>
            <Typography variant="h3">Additional Payment Settings</Typography>
            <FieldData label="Details">
                {!isEditing ? (
                    loanProgram.payment.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Details"
                        id="details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.payment.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Payments;

/** Props for the Payments component */
type PaymentsProps = Required<LoanProgramDataProps>;
