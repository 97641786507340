import { Clear, Search } from '@mui/icons-material';
import {
    IconButton,
    InputAdornment,
    TextField,
} from '@nelnet/unifi-components-react';

/**
 * Component that renders a search bar with an optional clear button.
 * @param {SearchBarProps} props - The component properties.
 */
const SearchBar = (props: SearchBarProps) => {
    return (
        <TextField
            label={props.label}
            value={props.searchTerm}
            disabled={props.disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setSearchTerm(event.target.value)
            }
            fullWidth={props.fullWidth}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {props.searchTerm ? (
                            <IconButton
                                aria-label="Clear search"
                                onClick={() => props.setSearchTerm('')}
                                edge="end"
                            >
                                <Clear />
                            </IconButton>
                        ) : (
                            <Search />
                        )}
                    </InputAdornment>
                ),
            }}
            inputProps={{ 'aria-label': props.label }}
        />
    );
};

/**
 * Props for the search bar component
 * @property {string} searchTerm - The current search term.
 * @property {boolean} disabled - The status to check if a component should disable or not
 * @property {function(searchTerm: string): void} setSearchTerm - A function to update the search term.
 * @property {string} label - The label for the search input.
 * @property {boolean} fullWidth - Whether the search bar should span the full width of its container.
 */
type SearchBarProps = {
    searchTerm: string;
    disabled?: boolean;
    setSearchTerm: (searchTerm: string) => void;
    label: string;
    fullWidth?: boolean;
};

export default SearchBar;
