import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { DisabilitySection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Disability section of the loan program page
 */
const Disability = ({ loanProgram, isEditing, onEdit }: DisabilityProps) => {
    /**
     * Helper function to call when any data in the Disability section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<DisabilitySection>) =>
        onEdit({
            disability: {
                ...loanProgram.disability,
                ...data,
            },
        });

    return (
        <FieldData label="Disability Details">
            {!isEditing ? (
                loanProgram.disability.details
            ) : (
                <TextField
                    fullWidth
                    placeholder="Disability Details"
                    id="disability-details"
                    minRows={multilineFieldHeight}
                    multiline
                    value={loanProgram.disability.details ?? ''}
                    onChange={(event) =>
                        onEditSection({
                            details: event.target.value || undefined,
                        })
                    }
                />
            )}
        </FieldData>
    );
};

export default Disability;

/** Props for the Disability component */
type DisabilityProps = Required<LoanProgramDataProps>;
