import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    useTheme,
} from '@nelnet/unifi-components-react';
import { ScraLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the California Lender Guidelines Table
 */
const CaliforniaLenderGuidelinesTable = ({
    lenders,
}: CaliforniaLenderGuidelinesTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                CA Rules for Student Loans
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                CA Rules for Personal Loans
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) => {
                return lender.data.map((loanProgram) => {
                    return (
                        <TableRow
                            key={`${lender.lenderId}-${loanProgram.loanProgramId}`}
                        >
                            <MatrixTableCell>
                                {lender.lenderName}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {loanProgram.loanProgramNumber}
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.caLenderGuidelines
                                        ?.caRulesForStudentLoans
                                }
                            </MatrixTableCell>
                            <MatrixTableCell>
                                {
                                    loanProgram.caLenderGuidelines
                                        ?.caRulesForPersonalLoans
                                }
                            </MatrixTableCell>
                        </TableRow>
                    );
                });
            })}
        </>
    );

    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader>
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for ForbearanceNoteTemplateDisplay component */
type CaliforniaLenderGuidelinesTableProps = {
    /** An array of ScraLender, for displaying on the frontend */
    lenders: ScraLender[];
};

export default CaliforniaLenderGuidelinesTable;
