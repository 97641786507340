import { TextField } from '@mui/material';
import FieldData from '../../../../components/field-data';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { InterestRateSection } from '../../../../models';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Interest Rates section of the loan program page
 */
const InterestRates = ({
    loanProgram,
    isEditing,
    onEdit,
}: InterestRatesProps) => {
    /**
     * Helper function to call when any data in the Interest Rates section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<InterestRateSection>) =>
        onEdit({
            interestRate: {
                ...loanProgram.interestRate,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Interest Method">
                {loanProgram.interestRate.interestMethod}
            </FieldData>

            <FieldData label="Interest Calculation Type">
                {loanProgram.interestRate.interestCalculationType}
            </FieldData>
            <FieldData label="Interest Rate Type">
                {loanProgram.interestRate.interestRateType}
            </FieldData>
            {loanProgram.interestRate.variableInterestRateDetails && (
                <>
                    <FieldData label="Variable Interest Rate Base Code">
                        {
                            loanProgram.interestRate.variableInterestRateDetails
                                .baseRateCode
                        }
                    </FieldData>
                    <FieldData label="Variable Interest Rate Frequency">
                        {
                            loanProgram.interestRate.variableInterestRateDetails
                                .variableInterestRateFrequency
                        }
                    </FieldData>
                    <FieldData label="Variable Minimum Interest Rate">
                        {
                            loanProgram.interestRate.variableInterestRateDetails
                                .variableMinInterestRate
                        }
                    </FieldData>
                    <FieldData label="Variable Maximum Interest Rate">
                        {
                            loanProgram.interestRate.variableInterestRateDetails
                                .variableMaxInterestRate
                        }
                    </FieldData>
                </>
            )}
            <FieldData label="Additional Interest Rate Details">
                {!isEditing ? (
                    loanProgram.interestRate.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Incentive Details"
                        id="additional-incentive-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.interestRate.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default InterestRates;

/** Props for the InterestRates component */
type InterestRatesProps = Required<LoanProgramDataProps>;
