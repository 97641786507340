import {
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { ServicingLoanProgramInformationSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import Address from '../../../../components/address';

/**
 * Component for the Servicing Loan Program Information section of the loan
 * program page
 */
const ServicingLoanProgramInformation = ({
    loanProgram,
    isEditing,
    onEdit,
}: ServicingLoanProgramInformationProps) => {
    /**
     * Helper function to call when any data in the Servicing Loan Program
     * Information section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (
        data: Partial<ServicingLoanProgramInformationSection>
    ) =>
        onEdit({
            servicingLoanProgramInformation: {
                ...loanProgram.servicingLoanProgramInformation,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Loan Program Type">
                {!isEditing ? (
                    loanProgram.servicingLoanProgramInformation.loanProgramType
                ) : (
                    <RadioGroup
                        row
                        name="loan-program-type"
                        value={
                            loanProgram.servicingLoanProgramInformation
                                .loanProgramType ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                loanProgramType: event.target.value,
                            })
                        }
                    >
                        <FormControlLabel
                            value="School"
                            label="School"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="Personal"
                            label="Personal"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="Refinance"
                            label="Refinance"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="ISA"
                            label="ISA"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="Other"
                            label="Other"
                            control={<Radio />}
                        />
                    </RadioGroup>
                )}
            </FieldData>
            <FieldData label="Brand">
                {!isEditing ? (
                    loanProgram.servicingLoanProgramInformation.brand
                ) : (
                    <RadioGroup
                        row
                        name="choose-brand"
                        value={
                            loanProgram.servicingLoanProgramInformation.brand ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                brand: event.target.value,
                            })
                        }
                    >
                        <FormControlLabel
                            value="White Label"
                            label="White Label"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value="Firstmark"
                            label="Firstmark"
                            control={<Radio />}
                        />
                    </RadioGroup>
                )}
            </FieldData>
            <FieldData label="Website Address">
                {!isEditing ? (
                    loanProgram.servicingLoanProgramInformation
                        .websiteAddress ? (
                        <Typography>
                            <Link
                                href={
                                    loanProgram.servicingLoanProgramInformation
                                        .websiteAddress
                                }
                            >
                                {
                                    loanProgram.servicingLoanProgramInformation
                                        .websiteAddress
                                }
                            </Link>
                        </Typography>
                    ) : (
                        'No website address provided'
                    )
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Website Address"
                        id="website-address"
                        value={
                            loanProgram.servicingLoanProgramInformation
                                .websiteAddress ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                websiteAddress: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Lockbox Address">
                <Address
                    id="lockbox-address"
                    value={
                        loanProgram.servicingLoanProgramInformation
                            .lockboxAddress
                    }
                    isEditing={isEditing}
                    onChange={(address) =>
                        onEditSection({
                            lockboxAddress: address,
                        })
                    }
                />
            </FieldData>
            <FieldData label="Correspondence Address">
                <Address
                    id="correspondence-address"
                    value={
                        loanProgram.servicingLoanProgramInformation
                            .correspondenceAddress
                    }
                    isEditing={isEditing}
                    onChange={(address) =>
                        onEditSection({
                            correspondenceAddress: address,
                        })
                    }
                />
            </FieldData>
        </>
    );
};

export default ServicingLoanProgramInformation;

/** Props for the ServicingLoanProgramInformation component */
type ServicingLoanProgramInformationProps = Required<LoanProgramDataProps>;
