import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { ThirdPartyCollectorSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Third-Party Collector section of the loan program page
 */
const ThirdPartyCollector = ({
    loanProgram,
    isEditing,
    onEdit,
}: ThirdPartyCollectorProps) => {
    /**
     * Helper function to call when any data in the Third-Party Collector section
     * is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<ThirdPartyCollectorSection>) =>
        onEdit({
            thirdPartyCollector: {
                ...loanProgram.thirdPartyCollector,
                ...data,
            },
        });

    return (
        <>
            <FieldData label="Third Party Collector Details">
                {!isEditing ? (
                    loanProgram.thirdPartyCollector.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Third Party Collector Details"
                        id="third-party-collector-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.thirdPartyCollector.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Additional Third Party Collector Details">
                {!isEditing ? (
                    loanProgram.thirdPartyCollector.thirdPartyCollectorDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Third Party Collector Details"
                        id="additional-third-party-collector-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.thirdPartyCollector
                                .thirdPartyCollectorDetails ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                thirdPartyCollectorDetails:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default ThirdPartyCollector;

/** Props for the ThirdPartyCollector component */
type ThirdPartyCollectorProps = Required<LoanProgramDataProps>;
