import { Typography, Link } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { OriginationSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Originations section of the loan program page
 */
const Originations = ({
    loanProgram,
    isEditing,
    onEdit,
}: OriginationsProps) => {
    /**
     * Helper function to call when any data in the Originations section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<OriginationSection>) =>
        onEdit({
            origination: {
                ...loanProgram.origination,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">General Information</Typography>
            <FieldData label="Originated By">
                {!isEditing ? (
                    loanProgram.origination.originatedBy
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Originated By"
                        id="originated-by"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.originatedBy ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                originatedBy: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData
                label="Originations website"
                emptyFieldText="No website address provided"
            >
                {!isEditing ? (
                    loanProgram.origination.originationsWebsite && (
                        <Typography>
                            <Link
                                href={
                                    loanProgram.origination.originationsWebsite
                                }
                            >
                                {loanProgram.origination.originationsWebsite}
                            </Link>
                        </Typography>
                    )
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Originations website"
                        id="originations-website"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.origination.originationsWebsite ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                originationsWebsite:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Disbursement Information</Typography>
            <FieldData label="Disbursement Information">
                {!isEditing ? (
                    loanProgram.origination.disbursementInformation
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Disbursement Information"
                        id="disbursement-information"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.origination.disbursementInformation ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                disbursementInformation:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Application Process</Typography>
            <FieldData label="Application Process">
                {!isEditing ? (
                    loanProgram.origination.applicationProcess
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Application Process"
                        id="application-process"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.applicationProcess ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                applicationProcess:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Eligibility Requirements</Typography>
            <FieldData label="Eligibility Requirement">
                {!isEditing ? (
                    loanProgram.origination.eligibilityRequirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Eligibility Requirement"
                        id="eligibility-requirement"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.origination.eligibilityRequirements ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                eligibilityRequirements:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Credit Requirements</Typography>
            <FieldData label="Credit Requirements">
                {!isEditing ? (
                    loanProgram.origination.creditRequirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Credit Requirement"
                        id="credit-requirement"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.creditRequirements ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                creditRequirements:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Proof of Income</Typography>
            <FieldData label="Proof of Income Requirements">
                {!isEditing ? (
                    loanProgram.origination.proofOfIncomeRequirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Proof of Income Requirement"
                        id="proof-of-income-requirement"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.origination.proofOfIncomeRequirements ??
                            ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                proofOfIncomeRequirements:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Repayment Terms</Typography>
            <FieldData label="Repayment Terms">
                {!isEditing ? (
                    loanProgram.origination.repaymentTerms
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Repayment Terms"
                        id="repayment-terms"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.repaymentTerms ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                repaymentTerms: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Growth Rate</Typography>
            <FieldData label="Growth Rate">
                {!isEditing ? (
                    loanProgram.origination.growthRate
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Growth Rate"
                        id="growth-rate"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.growthRate ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                growthRate: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Agreement Status</Typography>
            <FieldData label="Agreement Status">
                {!isEditing ? (
                    loanProgram.origination.agreementStatus
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Agreement Status"
                        id="agreement-status"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.agreementStatus ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                agreementStatus:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Additional Origination Details</Typography>
            <FieldData label="Additional Origination Details">
                {!isEditing ? (
                    loanProgram.origination.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Origination Details"
                        id="additional-origination-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.origination.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Originations;

/** Props for the Originations component */
type OriginationsProps = Required<LoanProgramDataProps>;
