import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { CosignerReleaseSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Co-Signer Release section of the loan program page
 */
const CoSignerRelease = ({
    loanProgram,
    isEditing,
    onEdit,
}: CoSignerReleaseProps) => {
    /**
     * Helper function to call when any data in the Co-Signer Release section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<CosignerReleaseSection>) =>
        onEdit({
            cosignerRelease: {
                ...loanProgram.cosignerRelease,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Eligibility</Typography>

            <FieldData label="Co-signer Release Counter">
                {!isEditing ? (
                    loanProgram.cosignerRelease.eligibility
                        ?.cosignerReleaseCounter
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Co-signer Release Counter"
                        id="co-signer-release-counter"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.cosignerRelease.eligibility
                                ?.cosignerReleaseCounter ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                eligibility: {
                                    ...loanProgram.cosignerRelease.eligibility,
                                    cosignerReleaseCounter:
                                        event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Co-signer Eligibility Requirements">
                {!isEditing ? (
                    loanProgram.cosignerRelease.eligibility
                        ?.cosignerReleaseEligibilityRequirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Co-signer Eligibility Requirements"
                        id="co-signer-eligibility-requirements"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.cosignerRelease.eligibility
                                ?.cosignerReleaseEligibilityRequirements ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                eligibility: {
                                    ...loanProgram.cosignerRelease.eligibility,
                                    cosignerReleaseEligibilityRequirements:
                                        event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>
            <FieldData label="Additional Co-signer Process Details">
                {!isEditing ? (
                    loanProgram.cosignerRelease.eligibility
                        ?.additionalCosignerProcessDetails
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Co-signer Process Details"
                        id="additional-co-signer-process-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.cosignerRelease.eligibility
                                ?.additionalCosignerProcessDetails ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                eligibility: {
                                    ...loanProgram.cosignerRelease.eligibility,
                                    additionalCosignerProcessDetails:
                                        event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">Reconsiderations</Typography>

            <FieldData label="Co-signer Reconsideration Requirements">
                {!isEditing ? (
                    loanProgram.cosignerRelease
                        .cosignerReconsiderationRequirements
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Co-signer Reconsideration Requirements"
                        id="co-signer-reconsideration-requirements"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.cosignerRelease
                                .cosignerReconsiderationRequirements ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                cosignerReconsiderationRequirements:
                                    event.target.value || undefined,
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default CoSignerRelease;

/** Props for the CoSignerRelease component */
type CoSignerReleaseProps = Required<LoanProgramDataProps>;
