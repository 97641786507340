import { Typography } from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import { FeesSection } from '../../../../models';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';

/**
 * Component for the Fees section of the loan program page
 */
const Fees = ({ loanProgram, isEditing, onEdit }: FeesProps) => {
    /**
     * Helper function to call when any data in the Fees section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<FeesSection>) =>
        onEdit({
            feesInformation: {
                ...loanProgram.feesInformation,
                ...data,
            },
        });

    return (
        <>
            <Typography variant="h3">Late Fees</Typography>
            <FieldData label="Days past due when a late fee is accessed">
                {loanProgram.feesInformation.lateFee.graceDays}
            </FieldData>
            <FieldData label="Late Fee Amount/Type">
                {`${loanProgram.feesInformation.lateFee.amount} / ${loanProgram.feesInformation.lateFee.type}`}
            </FieldData>
            <FieldData label="Minimum Late Fee Amount">
                {loanProgram.feesInformation.lateFee.minAmount}
            </FieldData>
            <FieldData label="Maximum Late Fee Amount">
                {loanProgram.feesInformation.lateFee.maxAmount}
            </FieldData>
            <FieldData label="Additional Late Fee Details">
                {!isEditing ? (
                    loanProgram.feesInformation.lateFee.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional Late Fee Details"
                        id="additional-late-fee-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={
                            loanProgram.feesInformation.lateFee.details ?? ''
                        }
                        onChange={(event) =>
                            onEditSection({
                                lateFee: {
                                    ...loanProgram.feesInformation.lateFee,
                                    details: event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>

            <Typography variant="h3">NSF Fees</Typography>
            <FieldData label="NSF Fee Amount/Type">
                {`${loanProgram.feesInformation.nsfFee.amount} / ${loanProgram.feesInformation.nsfFee.type}`}
            </FieldData>
            <FieldData label="Minimum NSF Fee Amount">
                {loanProgram.feesInformation.nsfFee.minAmount}
            </FieldData>
            <FieldData label="Maximum NSF Fee Amount">
                {loanProgram.feesInformation.nsfFee.maxAmount}
            </FieldData>
            <FieldData label="Additional NSF Fee Details">
                {!isEditing ? (
                    loanProgram.feesInformation.nsfFee.details
                ) : (
                    <TextField
                        fullWidth
                        placeholder="Additional NSF Fee Details"
                        id="additional-NSF-fee-details"
                        multiline
                        minRows={multilineFieldHeight}
                        value={loanProgram.feesInformation.nsfFee.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                nsfFee: {
                                    ...loanProgram.feesInformation.nsfFee,
                                    details: event.target.value || undefined,
                                },
                            })
                        }
                    />
                )}
            </FieldData>
        </>
    );
};

export default Fees;

/** Props for the Fees component */
type FeesProps = Required<LoanProgramDataProps>;
