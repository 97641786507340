import { TableCell } from '@mui/material';
import {
    Box,
    Divider,
    Panel,
    PanelContent,
    PanelHeader,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@nelnet/unifi-components-react';
import { TextField } from '@mui/material';
import { LoanProgramDataProps } from '../../components/loan-program-data';
import FieldData from '../../../../components/field-data';
import { multilineFieldHeight } from '../../../../global';
import { LoanModificationsSection } from '../../../../models';

/**
 * Component for the Loan Modifications section of the loan program page
 */
const LoanModifications = ({
    loanProgram: { loanModifications },
    isEditing,
    onEdit,
}: LoanModificationsProps) => {
    /**
     * Helper function to call when any data in the Loan Modifications section is edited
     *
     * @param data any data that is edited
     */
    const onEditSection = (data: Partial<LoanModificationsSection>) =>
        onEdit({
            loanModifications: {
                ...loanModifications,
                ...data,
            },
        });

    return (
        <>
            <Box marginBottom={2}>
                <Typography variant="h3">Modifications</Typography>
                {loanModifications.modifications &&
                loanModifications.modifications.length > 0 ? (
                    loanModifications.modifications.map(
                        (modification, index) => (
                            <Box key={index} marginRight={-2}>
                                <Panel elevation={0} enableCollapse>
                                    <PanelHeader
                                        title={
                                            modification.description ||
                                            'Loan Modification'
                                        }
                                    />

                                    <PanelContent>
                                        <FieldData label="Active">
                                            {modification.isActive}
                                        </FieldData>

                                        <FieldData label="Temporary">
                                            {modification.isTemporary}
                                        </FieldData>

                                        <FieldData label="Is Backdated Allowed?">
                                            {modification.isBackdatedAllowed}
                                        </FieldData>

                                        <FieldData label="Can We Bring the Loan Current When Processing this Loan Modification?">
                                            {modification.canBringLoanCurrent}
                                        </FieldData>

                                        <FieldData label="Interest Capitalizes at the End of the Loan Modification">
                                            {
                                                modification.doesInterestCapitalizeAtEnd
                                            }
                                        </FieldData>

                                        <FieldData
                                            label={
                                                modification.reductions
                                                    ?.length === 1
                                                    ? 'Reduction'
                                                    : 'Reductions'
                                            }
                                        >
                                            {modification.reductions &&
                                            modification.reductions.length >
                                                0 ? (
                                                <Table
                                                    size="small"
                                                    sx={{ marginBottom: 1.5 }}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Type
                                                            </TableCell>
                                                            <TableCell>
                                                                Amount
                                                            </TableCell>
                                                            <TableCell>
                                                                Duration
                                                            </TableCell>
                                                            <TableCell>
                                                                Allow Paid Ahead
                                                                Status
                                                            </TableCell>
                                                            <TableCell>
                                                                Cap Interest at
                                                                Start
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {modification.reductions.map(
                                                            (
                                                                reduction,
                                                                index
                                                            ) => (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        {reduction.type ??
                                                                            'No information provided'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {reduction.amount ??
                                                                            'No information provided'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {reduction.duration ??
                                                                            'No information provided'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {reduction.isPaidAheadStatusAllowed ===
                                                                        undefined
                                                                            ? 'No information provided'
                                                                            : reduction.isPaidAheadStatusAllowed
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {reduction.capInterestAtStart ===
                                                                        undefined
                                                                            ? 'No information provided'
                                                                            : reduction.capInterestAtStart
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            ) : (
                                                <Typography paragraph>
                                                    No information provided
                                                </Typography>
                                            )}
                                        </FieldData>
                                    </PanelContent>
                                </Panel>
                                <Divider />
                            </Box>
                        )
                    )
                ) : (
                    <Typography paragraph>No information provided</Typography>
                )}
            </Box>

            <Typography variant="h3">Additional Details</Typography>
            <FieldData label="Additional Details">
                {!isEditing ? (
                    loanModifications.details
                ) : (
                    <TextField
                        value={loanModifications.details ?? ''}
                        onChange={(event) =>
                            onEditSection({
                                details: event.target.value || undefined,
                            })
                        }
                        fullWidth
                        placeholder="Additional Details"
                        id="loan-modifications-additional-details"
                        multiline
                        minRows={multilineFieldHeight}
                    />
                )}
            </FieldData>
        </>
    );
};

export default LoanModifications;

/** Props for the LoanModifications component */
type LoanModificationsProps = Required<LoanProgramDataProps>;
