import { Button, Menu, MenuItem } from '@nelnet/unifi-components-react';
import { ExpandMore } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { compareAlphabetically } from '../utils';

/**
 * Component that displays a dropdown menu of matrices.
 */
export const MatricesTab = () => {
    const history = useHistory();

    const matrices: { label: string; href: string }[] = [
        {
            label: 'Borrower Incentive',
            href: 'borrower-incentive',
        },
        {
            label: 'Cancel-Refund Cash Noncash',
            href: 'cancel-refund-cash-noncash',
        },
        {
            label: 'Charge Off and Claim Filed',
            href: 'charge-off-claim-filed',
        },
        { label: 'Death', href: 'death' },
        { label: 'Disability', href: 'disability' },
        { label: 'Forbearance', href: 'forbearance' },
        { label: 'Late Fee', href: 'late-fee' },
        { label: 'SCRA', href: 'scra' },
    ];

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleMenuClose = (href: string) => {
        history.push(
            location.pathname.includes('/matrix') ? href : `/matrix/${href}`
        );
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                endIcon={<ExpandMore />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="text"
                color="inherit"
            >
                Matrices
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                disableAutoFocusItem
                elevation={1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {matrices
                    .sort((a, b) => compareAlphabetically(a.label, b.label))
                    .map((matrix) => (
                        <MenuItem
                            onClick={() => handleMenuClose(matrix.href)}
                            key={matrix.href}
                        >
                            {matrix.label}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export default MatricesTab;
