import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    useTheme,
} from '@nelnet/unifi-components-react';
import { DisabilityLender } from '../../models';
import { tableHeadersSpacing } from '../../global';
import MatrixTableCell from '../../components/matrix-table-cell';
import { TableContainer } from '@mui/material';

/**
 * This component handles rendering the borrower incentive matrix table
 */
const DisabilityMatrixTable = ({ lenders }: DisabilityMatrixTableProps) => {
    const theme = useTheme();

    //  Set constants for the minimum width of each table header
    const minWidthBooleansOrNumbers = theme.spacing(
        tableHeadersSpacing.minWidthBooleansOrNumbers
    );
    const minWidthMultipleLines = theme.spacing(
        tableHeadersSpacing.minWidthMultipleLines
    );
    const minWidthOneLine = theme.spacing(tableHeadersSpacing.minWidthOneLine);

    // Define a component for rendering the table headers
    const TableHeaders = () => (
        <TableRow
            sx={{
                '& th': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                },
            }}
        >
            <TableCell sx={{ minWidth: minWidthOneLine }}>Lender</TableCell>
            <TableCell sx={{ minWidth: minWidthOneLine }}>
                Loan Program
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Permanent or Temporary Disability
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Perm or Temp Disability Limitations
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                VA Documents Required
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                SSA Documents Required
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Other Documents Required
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Co-Signer Eligibility/Approval
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Admin Forbearance
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Full and Credit Suppression
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Approval Process
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                How is Documentation Received from Borrower? Is it forwarded to
                lender?
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Status Change/Write Off for Borrower/Benefitting Student
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Who does the Approval or Denial
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Missing Information Letter
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Approval Letter Sender
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                AUD
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Backing Off Payments
            </TableCell>
            <TableCell sx={{ minWidth: minWidthBooleansOrNumbers }}>
                Switch Parti (Datafix)
            </TableCell>
            <TableCell sx={{ minWidth: minWidthMultipleLines }}>
                Remove cosigner from STAR?
            </TableCell>
        </TableRow>
    );

    // Define a component for rendering the table rows
    const TableRows = () => (
        <>
            {lenders.map((lender) =>
                lender.data.map((loanProgram) => (
                    <TableRow
                        key={`${lender.lenderId}-${loanProgram.loanProgramId}`}
                    >
                        <MatrixTableCell>{lender.lenderName}</MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.loanProgramNumber}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.permOrTemp}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.permOrTempLimit}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.vaDocumentsRequired}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.ssaDocumentsRequired}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.otherDocsRequired}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.cosignerEligibility}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.adminForbearance}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.fullAndCreditSuppression}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.approvalProcess}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.howDocsReceived}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.statusChange}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.approver}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.missingInfoLetter}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.approvalLetterSender}
                        </MatrixTableCell>
                        <MatrixTableCell>{loanProgram.aud}</MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.backingOffPayments}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.switchParti}
                        </MatrixTableCell>
                        <MatrixTableCell>
                            {loanProgram.removeCosignerFromStar}
                        </MatrixTableCell>
                    </TableRow>
                ))
            )}
        </>
    );

    // Render the matrix on the frontend
    return (
        <Paper style={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table size="medium" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableHeaders />
                    </TableHead>
                    <TableBody>
                        <TableRows />
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

/** Props for DisabilityMatrixTable component */
type DisabilityMatrixTableProps = {
    /** An array of DisabilityLender, for displaying on the frontend */
    lenders: DisabilityLender[];
};

export default DisabilityMatrixTable;
