import { Button, Container } from '@mui/material';
import { Typography } from '@nelnet/unifi-components-react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Link } from 'react-router-dom';

/**
 * Component for an error page to display when the requsted page does not exist
 * or its data cannot be not found
 */
const ErrorPage = () => (
    <Container>
        <Typography variant="h1">Page Not Found</Typography>
        <Typography paragraph>
            The page you are looking for does not exist.
        </Typography>

        <Button
            startIcon={<ArrowBackIosNew />}
            component={Link}
            to="/"
            size="small"
            variant="outlined"
        >
            Go Back Home
        </Button>
    </Container>
);

export default ErrorPage;
