import axios from 'axios';
import { useEffect, useState } from 'react';
import { getAPIBaseURL } from '../../env';
import { Container, Skeleton } from '@mui/material';
import { Notification, Typography } from '@nelnet/unifi-components-react';
import { Check, Error } from '@mui/icons-material';

/**
 * Component for the status page that checks the health of the API
 */
const StatusPage = () => {
    // State for whether the API is responding
    const [isHealthy, setIsHealthy] = useState<boolean>();

    // Perform a health check for the API
    const baseUrl = getAPIBaseURL();
    useEffect(() => {
        axios
            .get(`${baseUrl}health`)
            .then(() => setIsHealthy(true))
            .catch(() => setIsHealthy(false));
    }, []);

    return (
        <Container maxWidth="sm">
            <Typography variant="h1" textAlign="center" gutterBottom>
                Clarity API Status
            </Typography>

            {isHealthy === undefined ? (
                <Skeleton variant="rounded" width="100%" height={66} />
            ) : (
                <Notification
                    type={isHealthy ? 'success' : 'error'}
                    icon={isHealthy ? <Check /> : <Error />}
                >
                    <Typography variant="button" component="p">
                        {isHealthy ? 'Operational' : 'Not Operational'}
                    </Typography>
                </Notification>
            )}
        </Container>
    );
};

export default StatusPage;
